import React, { useEffect, useState } from 'react';
import { makeStyles, theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as actions from '../../../../store/actions';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
      },
      table: {
        minWidth: 650,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      paper: {
        padding: theme.spacing(1),
        margin: "auto",
        maxWidth: 350
      },
      title: {
        fontSize: 14,
      },
      buttons: {
        marginTop: "30px"
      },
      submitButton: {
        marginLeft: "20px"
      }
  }));

  const EditAddressDialog = (props) =>
  {
        const { open,modus,addressToEdit } = props;
        console.log('address to edit=',addressToEdit)
                
        const [openDialog, setOpenDialog] = React.useState(open);
        //const [err, setErr] = React.useState(false);
        const[helperText, setHelperText] = React.useState(undefined);
        //const handleClose = () => { setOpenDialog(false); };
        const classes = useStyles();
        const [buttonsAllowed, setButtonsAllowed] = useState(false);

        const [zip, setZip] = useState('');
        const [region, setRegion] = useState('');
        const [district, setDistrict] = useState('');
        const [city, setCity] = useState('');
        const [street, setStreet] = useState('');
        const [home, setHome] = useState('');
        const [corp, setCorp] = useState('');
        const [flat, setFlat] = useState('');
        const [id, setId] = useState('');
        const [description, setDescription] = useState('');

        React.useEffect(() => {
            if(addressToEdit && addressToEdit.zip) setZip(addressToEdit.zip);
            if(addressToEdit && addressToEdit.region) setRegion(addressToEdit.region);
            if(addressToEdit && addressToEdit.district) setDistrict(addressToEdit.district);
            if(addressToEdit && addressToEdit.city) setCity(addressToEdit.city);
            if(addressToEdit && addressToEdit.street) setStreet(addressToEdit.street);
            if(addressToEdit && addressToEdit.home) setHome(addressToEdit.home);
            if(addressToEdit && addressToEdit.corp) setCorp(addressToEdit.corp);
            if(addressToEdit && addressToEdit.flat) setFlat(addressToEdit.flat);
            if(addressToEdit && addressToEdit.id) setId(addressToEdit.id);
            if(addressToEdit && addressToEdit.description) setDescription(addressToEdit.description);
        }, [ ])


        const handleZipInput = event => {
            setZip(event.target.value);
            let act = zip !== event.target.value;
            setButtonsAllowed(act);
        };
        const handleRegionInput = event => {
          setRegion(event.target.value);
          setButtonsAllowed(region !== event.target.value);
        };
        const handleDistrictInput = event => {
          setDistrict(event.target.value);
          setButtonsAllowed(district !== event.target.value);
        };
        const handleCityInput = event => {
              setCity(event.target.value);
              setButtonsAllowed(city !== event.target.value);
        };
        const handleStreetInput = event => {
              setStreet(event.target.value);
              setButtonsAllowed(street !== event.target.value);
        };
        const handleHomeInput = event => {
            setHome(event.target.value);
            setButtonsAllowed(home !== event.target.value);
        };
        const handleCorpInput = event => {
            setCorp(event.target.value);
            setButtonsAllowed(corp !== event.target.value);
        };
        const handleFlatInput = event => {
            setFlat(event.target.value);
            setButtonsAllowed(flat !== event.target.value);
        };
        const handleDescriptionInput = event => {
            setDescription(event.target.value);
            setButtonsAllowed(flat !== event.target.value);
        };

        const handleSubmit = event => {
             let objectToSend = {id: props.userId, zip: zip, region: region, district: district, city: city, street: street, home: home, corp: corp, flat: flat, decription: description, modus: modus, addressId: modus == 'new' ? null : id};
            console.log("objectToSend=",objectToSend)
            props.saveUserAddress(objectToSend);
            props.dialogClose();
          };
          const handleReset = event => {
            
            props.dialogClose();
          };



        return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.dialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.modus == 'new' ? 'Новый адрес доставки' : 'Адрес доставки'}</DialogTitle>
            <DialogContent>
          
          <form className={classes.root} noValidate autoComplete="off">
          <TextField 
                        id="zip" 
                        label="Почтовый индекс" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={zip || ''}
                        onChange={handleZipInput}
                        />
                        <TextField 
                        id="region" 
                        label="Субъект федерации" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        required
                        value={region || ''}
                        onChange={handleRegionInput}
                        />
                        <TextField 
                        id="district" 
                        label="Район" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={district || ''}
                        onChange={handleDistrictInput}
                        />
                        <TextField 
                        id="city" 
                        label="Город / нас.пункт" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        required
                        value={city || ''}
                        onChange={handleCityInput}
                        />
                        <TextField 
                        id="street" 
                        label="Улица" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={street || ''}
                        onChange={handleStreetInput}
                        />
                        <TextField 
                        id="home" 
                        label="Дом" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        required
                        value={home || ''}
                        onChange={handleHomeInput}
                        />
                        <TextField 
                        id="corp" 
                        label="Корпус" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={corp || ''}
                        onChange={handleCorpInput}
                        />
                        <TextField 
                        id="flat" 
                        label="Квартира" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={flat || ''}
                        onChange={handleFlatInput}
                        />
                        <TextField 
                        id="description" 
                        label="Описание" 
                        fullWidth 
                        size="small"
                        margin="normal"
                        value={description || ''}
                        onChange={handleDescriptionInput}
                        />
          </form>

          </DialogContent>
          <DialogActions>
          
           <Button variant="contained" color="default" onClick={handleReset} >Отмена</Button>
           <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton} disabled={!buttonsAllowed}>Сохранить</Button>     
        </DialogActions>
        </Dialog>
        </React.Fragment>
        )
  }

  const mapStateToProps = state => {
    return {
      userId: state.auth.userId
    }
  };

  const mapDispatchToProps = dispatch => {
    return {
        saveUserAddress: (objectToSend) => dispatch(actions.saveUserAddress(objectToSend))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressDialog);  
//export default withRouter(EditAddressDialog);

