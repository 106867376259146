import * as React from "react"

function CoinsSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}
    >
      <path d="M63.9 25c-.6-5.2-9.8-8-18.9-8-2.4 0-4.8.2-7 .6V8h-.1c-.5-5.2-9.7-8-18.9-8S.6 2.8.1 8H0v48c0 5.6 9.6 8.5 19 8.5 8.6 0 17.3-2.4 18.8-7.1 2.2.4 4.7.6 7.2.6 9.4 0 19-2.9 19-8.5L63.9 25zm-3.9.5c0 1.6-5.7 4.5-15 4.5-2.5 0-4.8-.2-7-.6v-7.8c2.2-.4 4.5-.6 7-.6 9.3 0 15 2.9 15 4.5zM19 4c9.3 0 15 2.9 15 4.5S28.3 13 19 13 4 10.1 4 8.5 9.7 4 19 4zM4 13.9c3.7 2 9.3 3.1 15 3.1s11.3-1.1 15-3.1V20c0 1.6-5.7 4.5-15 4.5S4 21.6 4 20v-6.1zm0 11.5c3.7 2 9.3 3.1 15 3.1s11.3-1.1 15-3.1V32c0 1.6-5.7 4.5-15 4.5S4 33.6 4 32v-6.6zm0 12c3.7 2 9.3 3.1 15 3.1s11.3-1.1 15-3.1V44c0 1.6-5.7 4.5-15 4.5S4 45.6 4 44v-6.6zm15 23.1c-9.3 0-15-2.9-15-4.5v-6.6c3.7 2 9.3 3.1 15 3.1s11.3-1.1 15-3.1V56c0 1.6-5.7 4.5-15 4.5zM45 54c-2.5 0-4.8-.2-7-.6v-7.9c2.2.4 4.6.6 7 .6 5.7 0 11.3-1.1 15-3.1v6.6c0 1.5-5.7 4.4-15 4.4zm0-12c-2.4 0-4.8-.2-7-.6v-7.9c2.2.4 4.6.6 7 .6 5.7 0 11.3-1.1 15-3.1v6.6c0 1.5-5.7 4.4-15 4.4z" />
    </svg>
  )
}

export default CoinsSvg