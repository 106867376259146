import AzureAD from 'react-aad-msal';
import {signInAuthProvider} from './authProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from "redux";

import {HashRouter as Router} from 'react-router-dom';
import createSagaMiddleware from "redux-saga";

import offersReducer from './store/reducers/offers';
import authReducer from './store/reducers/authReducer';
import graphReducer from './store/reducers/graphReducer';
import profileReducer from './store/reducers/profileReducer';
import ticketReducer from './store/reducers/ticket';

import {watchOffers } from "./store/sagas";

const rootReducer = combineReducers({
    offers: offersReducer,
    auth: authReducer,
    graph: graphReducer,
    profile: profileReducer,
    ticket: ticketReducer
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer,applyMiddleware(sagaMiddleware) );
sagaMiddleware.run(watchOffers);


ReactDOM.render(
  <Provider store={store}>
  <AzureAD provider={signInAuthProvider} forceLogin={true} reduxStore={store}>
  {/* <React.StrictMode> */}
    <Router>
    <App />
    </Router>
  {/* </React.StrictMode> */}
  </AzureAD>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
