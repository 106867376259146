 //import axios from 'axios';

// console.log('bielem!!!!')

 //export default axios.create({
 //    baseURL: 'http://localhost:44302/api/CustomerAccount/',
  
// })

// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
//     axios.defaults.headers.post['Content-Type'] = 'application/json';

import axios from 'axios';

const fetchMe = () => {
  
  let customerAccountServiceBaseUri = null;


  if (window.location.href.indexOf("localhost") > -1) {
  
    customerAccountServiceBaseUri = 'http://localhost:44302/';
  } else if (window.location.href.indexOf("dev") > -1) {
  
    customerAccountServiceBaseUri = 'https://dev-ws.mtwin.ru/CustomerAccountService/';
  } else {
  
    customerAccountServiceBaseUri = 'https://ws.mtwin.ru/CustomerAccountService/';
  }

  let uri = customerAccountServiceBaseUri + 'api/CustomerAccount/';
  const defaultOptions = {
    //baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: uri,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const storedToken = localStorage.getItem('token');
    //console.log('token from axios-offers.js===',storedToken);
    config.headers.Authorization =  storedToken ? storedToken : '';
    return config;
  });

  return instance;
};

export default fetchMe();


