import * as actionTypes from './actionTypes';

export const getOffers = (limit,offset,axios) => {
    return {
      type: actionTypes.GET_OFFERS,
      limit: limit,
      offset: offset,
      axios: axios
    };
  };

  export const getOffer = (offerId) => {
      return {
        type: actionTypes.GET_OFFER,
        offerId: offerId
      };
    };

    export const getCartCount = () => {
      return {
        type: actionTypes.GET_CART_COUNT
      };
    };

    export const getCartCountSuccess = (cartCount) => {
      return {
        type: actionTypes.GET_CART_COUNT_SUCCESS,
        cartCount: cartCount
      };
    };

    export const getOffersSuccess = (offerItems, offersCount) => {
      return {
        type: actionTypes.GET_OFFERS_SUCCESS,
        offerItems: offerItems,
        offersCount: offersCount
      };
    };
    
    export const getOffersCountByTypesSuccess = (myOfferCount, shopOfferCount) => {
      return {
        type: actionTypes.GET_OFFERS_COUNT_BY_TYPES_SUCCESS,
        myOfferCount: myOfferCount,
        shopOfferCount: shopOfferCount
      };
    };

    export const getOffersCount = () => {
      return {
        type: actionTypes.GET_OFFERS_COUNT
      };
    };


    export const getOffersCountSuccess = (incomingOffersCount, outgoingOffersCount, aoc) => {
      console.log('[offersReducer L56] acceptedOffersCount = ',aoc)
      return {
        type: actionTypes.GET_OFFERS_COUNT_SUCCESS,
        incomingOffersCount: incomingOffersCount,
        outgoingOffersCount: outgoingOffersCount,
        acceptedOffersCount: aoc
      };
    };

    export const getAlertsCount = () => {
      return {
        type: actionTypes.GET_ALERTS_COUNT
      };
    };


    export const getAlertsCountSuccess = (textMessageAlertCount) => {
      return {
        type: actionTypes.GET_ALERTS_COUNT_SUCCESS,
        textMessageAlertCount: textMessageAlertCount
      };
    };

    
    export const getAlertsArraySuccess = (alertsArray) => {
      return {
        type: actionTypes.GET_ALERTS_ARRAY_SUCCESS,
        alertsArray: alertsArray
      };
    };


  export const getChat = (offerId, limit, offset) => {
      return {
        type: actionTypes.GET_CHAT,
        offerId: offerId,
        limit: limit,
        offset: offset
      };
    };  

    export const clearChat = () => {
      return {
        type: actionTypes.CLEAR_CHAT,
      };
    };  
    
    export const clearOffer = () => {
      return {
        type: actionTypes.CLEAR_OFFER,
      };
    };

    export const getChatSuccess = (chatItems, chatCount) => {
      return {
        type: actionTypes.GET_CHAT_SUCCESS,
        chatItems: chatItems,
        chatCount: chatCount
      };
    };



  export const getOfferSuccess = (offer) => {
    return {
      type: actionTypes.GET_OFFER_SUCCESS,
      offer: offer
    };
  };  

  export const offerTimerCountdown = (timer,offerId) => {
    return {
      type: actionTypes.OFFER_TIMER_COUNTDOWN,
      timer: timer,
      offerId: offerId
    }
  }

  export const getOfferHistory = (limit,offset, name, offerId) => {
      return {
        type: actionTypes.GET_OFFER_HISTORY,
        limit: limit,
        offset: offset,
        name: name,
        offerId: offerId
      };
    };
  
    export const getOfferHistorySuccess = (offerHistoryItems, offerHistoryCount) => {
      return {
        type: actionTypes.GET_OFFER_HISTORY_SUCCESS,
        offerHistoryItems: offerHistoryItems,
        offerHistoryCount: offerHistoryCount
      };
    };  

    export const counterOffer = (offerId, offerPrice) => {
      return {
        type: actionTypes.COUNTER_OFFER,
        offerId: offerId,
        offerPrice: offerPrice
      }
    }

    export const acceptOffer = (offerId, env) => {
      return {
        type: actionTypes.ACCEPT_OFFER,
        offerId: offerId,
        env: env
      }
    }

    export const declineOffer = (offerId) => {
      return {
        type: actionTypes.DECLINE_OFFER,
        offerId: offerId
      }
    }

    export const postTextMessage = (offerId, textMessage) => {
      console.log('offerId=',offerId);
      console.log('textMessage=',textMessage);
      return {
        type: actionTypes.POST_TEXT_MESSAGE,
        offerId: offerId,
        textMessage: textMessage
      }
    }

    export const setSeenBy = (offerId, messageIds, messageType) => {
      console.log('offerId=',offerId);
      console.log('messageIds=',messageIds);
      return {
        type: actionTypes.SET_SEEN_BY,
        offerId: offerId,
        messageIds: messageIds,
        messageType: messageType
      }
    }

