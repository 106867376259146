import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import ButtonBase from '@material-ui/core/ButtonBase';
import {Link} from 'react-router-dom';
import WithNumberFormat from '../Hoc/WithNumberFormat';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { purple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import ForwardSvg from '../UI/Icons/ForwardSvg';
import { Tooltip } from '@material-ui/core';

// import Typography from '@material-ui/core/Typography';
// import {Link} from 'react-router-dom';
// import Grid from "@material-ui/core/Grid";
// import Paper from "@material-ui/core/Paper";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import WithNumberFormat from '../../../Hoc/WithNumberFormat';
// import { connect } from 'react-redux';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import * as actions from '../../../store/actions';
// import moment from 'moment';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';




const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      paper: {
        padding: theme.spacing(2),
        margin: "auto",
        
      },
      sq: {
        color: 'purple'
      },
      heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: 550
      },
      restOfHeading: {
        fontSize: theme.typography.pxToRem(14),
      },
      image: {
        width: 128,
        height: 128
      },
      columnQ: {
        flexBasis: '25.00%',
      },
      columnThird: {
        flexBasis: '30.00%',
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      orangeText: {
        color: "orange"
      },
      blueText: {
        color: "blue"
      },
      blueTextBordered: {
        color: "blue",
        fontSize: '1.1Em'
        //border: "1px solid"
      }
    
  }));

  const TicketItem = (props) =>
  {
    const { ticketId, ticketNumber, ticketName, stateReadable,loan, interestsToThisDay, thisRowIsSelected, history } = props;
    
    const classes = useStyles();
  

    const getCorrectStatereadableColor = stateReadable => {
    let toReturn = 'red';
    return toReturn;
  };

  const handleSwitch = (checked) => {
    alert(checked+ ' ' + ticketId);
    
  }

  const getRightStateReadable = stateReadable => {
    if(stateReadable == 'действующий') return (<Typography component="div" style={{ marginLeft: 10, color: 'lightslategrey',fontWeight: 650, textAlign: 'right' }} variant='body2' align='right' display='inline'>{stateReadable}</Typography>)
    else if(stateReadable == 'просрочен') return (<Typography component="div" style={{ marginLeft: 10, color: 'blue',fontWeight: 650, textAlign: 'right' }} variant='body2' align='right' display='inline'>{stateReadable}</Typography>)
    else if(stateReadable == 'за льготным сроком') return (<Typography component="div" style={{ marginLeft: 10, color: 'orange',fontWeight: 650, textAlign: 'right' }} variant='body2' align='right' display='inline'>{stateReadable}</Typography>)
    else if(stateReadable == 'на аукционе') return (<Typography component="div" style={{ marginLeft: 10, color: 'red',fontWeight: 650, textAlign: 'right' }} variant='body2' align='right' display='inline'>{stateReadable}</Typography>)
    else return (<Typography style={{ marginLeft: 10, color: 'grey',fontWeight: 650, textAlign: 'right' }} variant='body2' align='right' display='inline'>{stateReadable}</Typography>)
  }


    return (
      <React.Fragment>
          <div className={classes.root}>
         
          
          <Paper className={classes.paper} elevation={5} style={{marginBottom: "20px"}}>
          
            <Grid container spacing={7} >
            
                <Grid item xs={9} >
                  
                    <Grid container direction="column" >

                      <Grid container>
                        <Grid item xs={9} sm={12} md={3}> 

                    

                        <Link href="#" to={'/ticketcard/' + ticketId} onClick={e => {e.persist();props.handleClickOnLink();}} style={{textDecoration: 'none'}}>                      
                        <Typography color="secondary" style={{fontWeight: 650}} variant="body1" >{ticketNumber}</Typography>
                        </Link>

                    

                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>                       
                        <Typography color="secondary" style={{fontWeight: 650, textAlign: 'right'}} variant="body1" >{getRightStateReadable(stateReadable)}</Typography>
                        </Grid>
                        </Grid>

                        <Grid item xs >
                            <br/>
                        </Grid>

                        <Grid item xs >
                        <Typography variant="subtitle1" style={{fontWeight: 650}} >{ticketName}</Typography>
                        </Grid>
                        <Grid item xs >
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                        <Typography gutterBottom variant="body2" display="inline"></Typography>
                        </Grid>
                        <Grid item xs={8} >
                          
                        
                        </Grid>
                        </Grid>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>
              
                <Grid item xs={12} sm={12} md={3}>

                  
                <Grid container direction="column">
                <Grid item xs><span style={{fontSize: '0.7em', fontStyle: 'italic'}}>займ</span><br/><Typography color="secondary" style={{fontWeight: 650}} variant="body1" >{loan}</Typography></Grid>
                <Grid item xs></Grid>
                <Grid item xs><span style={{fontSize: '0.7em', fontStyle: 'italic'}}>проценты</span><br/><span style={{fontSize: '1.4em',fontWeight: 650}}><WithNumberFormat value={interestsToThisDay} digits={2} />&nbsp;₽</span></Grid>
                <Grid item xs><span style={{fontSize: '0.7em', fontStyle: 'italic'}}>включить в оплату</span><br/><Switch  checked={thisRowIsSelected} onChange={e => {e.persist();props.handleChange(e.target.checked, ticketId);}} name="checkedA" color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></Grid>
                {/* <Grid container>
                <Grid item xs={6}><span style={{fontSize: '0.7em', fontStyle: 'italic'}}>проценты</span><br/><span style={{fontSize: '1.4em',fontWeight: 650}}><WithNumberFormat value={interestsToThisDay} digits={2} />&nbsp;₽</span></Grid>
                <Grid item xs={6} style={{marginTop: '15px'}}><span style={{fontSize: '0.7em', fontStyle: 'italic'}}>включить в оплату</span><br/><Switch  checked={thisRowIsSelected} onChange={e => {e.persist();props.handleChange(e.target.checked, ticketId);}} name="checkedA" color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></Grid>
                </Grid> */}
                </Grid>

                </Grid>

                    
            </Grid>
            
            </Paper>
            
            

            </div>
  

      </React.Fragment>
    )
  }

  export default TicketItem;