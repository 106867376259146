import { put, call, takeEvery, all } from 'redux-saga/effects';
import { signInAuthProvider } from '../../authProvider';
import axios from '../../components/Profile/axios-profile';
import queryString from 'query-string';

import * as actions from '../actions';

export function* getAccessTokenSaga(action) {
    try {
        const response = yield signInAuthProvider.getAccessToken({ scopes: ['https://mtwin.onmicrosoft.com/CustomerAccountService/User.ReadWrite'] });
        yield all([put(actions.getOffersCount())]);
    } catch (error) {}
}

export function* getGraphAccessTokenSaga(action) {
    try {
        var url = 'http://localhost:44302/api/AzureGraph/GetAccessToken';

        const config = { headers: { Authorization: `Bearer ${action.ordinaryAccessToken}` } };

        const response = yield axios.get(url, config);
        console.log('getGraphAccessTokenSaga@@@@@@@@@@@@@@@@@=', response.data);
        yield put(actions.getGraphAccessTokenSuccess(response.data.access_token));
    } catch (error) {
        console.log('error=', error);
    }
}

export function* getUserCredentialsSaga(action) {
    try {
        //var url = "http://localhost:44302/api/AzureGraph/GetUserCredentials/";
        var url = 'GetUserCredentials/';
        //alert(process.env.REACT_APP_PIPS);
        // const config = {headers: {'Authorization': `Bearer ${action.accessToken}`}};

        const response = yield axios.get(url);
        let payLoad = response.data;
        // alert('симагонты!');
        console.log('paypaypayLoad=', payLoad);
        yield all([ put(actions.getUserCredentialsSuccess(payLoad)) ]);
        //yield put(actions.getUserCredentialsSuccess(payLoad));
    } catch (error) {}
}

export function* updateUserCredentialsSaga(action) {
    try {
        //var url = "http://localhost:44302/api/AzureGraph/UpdateUser/";
        var url = 'UpdateUser/';
        //const config = {headers: {'Authorization': `Bearer ${action.accessToken}`}};
        
        let objToSend = { justString: action.json };
        console.log('[auth.js(saga)/L51] objToSend=',objToSend)
        const response = yield axios.post(url, objToSend);
        let payLoad = response.data;
        console.log('[auth/saga] paypaypayLoadiki=', payLoad);
        console.log('[auth/saga] end')
        yield put(actions.getUserCredentials(action.accessToken));
    } catch (error) {}
}
