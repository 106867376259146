import * as actionTypes from '../actions/actionTypes';
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const initialState = {
  confirmationResult: undefined,
  customerId: undefined,
  checkWasSuccessfull: undefined,
  ticketList: [],
  singleTicket: null,
  ticketListMemorized: []
  
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {

        case actionTypes.DO_TICKET_CHECK_SUCCESS: 
        return {
            ...state,
            confirmationResult: action.payload.confirmationResult,
            customerId: action.payload.customerId,
            checkWasSuccessfull: action.payload.checkWasSuccessfull

        }

        case actionTypes.CONFIRM_THAT_USER_SUBSCRIBED_TO_TICKET:
        
          return {
              ...state,
              customerId: action.customerId
          }

          
        case actionTypes.GET_TICKET_LIST_SUCCESS:
          
          let toReturn = {};
          if(state.ticketListMemorized.length > 0)  toReturn = {
            ...state,
            ticketList: state.ticketListMemorized
          }
          else toReturn = {
              ...state,
              ticketList: action.payload
          }
          console.log('toReturn=',toReturn)
          return toReturn;

          case actionTypes.MEMORIZE_TICKET_LIST:
            
            return {
                ...state,
                ticketListMemorized: action.ticketList
            }
  

          case actionTypes.GET_TICKET_SUCCESS:
        
          return {
              ...state,
              singleTicket: action.payload
          }
  
          case actionTypes.CLEAR_SINGLE_TICKET:
        
          return {
              ...state,
              singleTicket: null
          }

        case actionTypes.RESET_CONFIRMATION_RESULT:
        return {
            ...state,
            confirmationResult: undefined,
            customerId: undefined,
            checkWasSuccessfull: undefined
        }
  
        
        default: return state;
  
      }
    }
  
      export default reducer;   