import * as actionTypes from '../actions/actionTypes';

const initialState = {
  graphAccessToken: null,
  userCredentials: {}
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
      case actionTypes.GET_GRAPH_ACCESS_TOKEN_SUCCESS: 
      console.log("[GET_GRAPH_ACCESS_TOKEN_SUCCESS]", action);
      return {
          ...state,
          graphAccessToken: action.token
      }

      case actionTypes.GET_USER_CREDENTIALS_SUCCESS:
      console.log("[GET_USER_CREDENTIALS_SUCCESS]", action);
      return {
          ...state,
          userCredentials: action.payLoad
      }

      default: return state;

    }
  }

    export default reducer;    