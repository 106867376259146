import { makeStyles, useTheme, ThemeProvider, theme, createMuiTheme } from '@material-ui/core/styles';
import Poppins from 'typeface-poppins';
import Lato from 'typeface-lato';

export const latoFont = createMuiTheme({
    typography: {
      fontFamily: [
        'Lato',
        'sans-serif',
      ].join(','),
  },});
  
export const poppinsFont = createMuiTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'sans-serif',
      ].join(','),
  },});