import * as React from "react"

const BackwardSvg = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
    fill={props.fill}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M437.02 74.982C388.667 26.628 324.38 0 256 0S123.333 26.628 74.98 74.982C26.628 123.333 0 187.619 0 256s26.628 132.667 74.98 181.019C123.333 485.372 187.62 512 256 512s132.667-26.628 181.02-74.981C485.372 388.667 512 324.381 512 256s-26.628-132.667-74.98-181.018zM256 491.602c-129.911 0-235.602-105.69-235.602-235.602S126.089 20.398 256 20.398 491.602 126.089 491.602 256 385.911 491.602 256 491.602z" />
    <path d="M450.365 178.795C428.7 124.297 384.824 81.139 329.987 60.387c-5.27-1.996-11.155.662-13.149 5.929-1.995 5.268.66 11.156 5.929 13.149 49.491 18.728 89.089 57.679 108.643 106.865a10.202 10.202 0 0 0 9.48 6.435c1.253 0 2.528-.234 3.766-.724 5.234-2.082 7.791-8.011 5.709-13.246zM291.371 49.898A211.02 211.02 0 0 0 256 46.917c-5.633 0-10.199 4.567-10.199 10.199S250.367 67.315 256 67.315c10.72 0 21.467.906 31.943 2.69.58.1 1.157.148 1.726.148 4.88 0 9.195-3.515 10.043-8.488.945-5.553-2.789-10.821-8.341-11.767zM227.57 258.732l77.61-77.61c5.596-5.595 8.677-13.036 8.677-20.948 0-7.914-3.082-15.354-8.677-20.948-5.595-5.596-13.035-8.677-20.948-8.677s-15.354 3.082-20.949 8.677l-98.558 98.558c-11.551 11.552-11.551 30.346 0 41.897l98.559 98.558c5.596 5.596 13.036 8.677 20.948 8.677s15.351-3.081 20.948-8.677c5.596-5.595 8.677-13.036 8.677-20.948 0-7.914-3.082-15.354-8.677-20.948l-77.61-77.611zm63.185 105.084a9.166 9.166 0 0 1-6.524 2.703 9.166 9.166 0 0 1-6.524-2.703l-98.558-98.558c-3.597-3.597-3.597-9.452 0-13.049l98.559-98.558c1.743-1.743 4.059-2.703 6.524-2.703s4.781.96 6.522 2.705a9.16 9.16 0 0 1 2.703 6.523c0 2.464-.96 4.78-2.703 6.523l-84.822 84.822c-3.983 3.984-3.983 10.442 0 14.425l84.823 84.823c1.743 1.742 2.703 4.059 2.703 6.523s-.961 4.781-2.703 6.524z" />
  </svg>
)

export default BackwardSvg