import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  }
}));

const GifSpinner = () => {
  const classes = useStyles();

  return (
  <img
    className={classes.img}
    alt="MtWin"
    src="/logo21.gif"
  />
)}



export default GifSpinner;