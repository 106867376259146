import * as React from "react"

function CreditCardSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="1.7em"
      height="1.7em"
      {...props}
    >
      <path d="M60 10H4c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h56c2.2 0 4-1.8 4-4V14c0-2.2-1.8-4-4-4zm0 4v4H4v-4h56zm0 8v4H4v-4h56zM4 50V30h56v20H4zm56 2v-2 2z" />
      <path d="M33 38h22v4H33zM21 34H10c-1.7 0-3 1.3-3 3v7c0 1.7 1.3 3 3 3h11c1.7 0 3-1.3 3-3v-7c0-1.7-1.3-3-3-3zm-1 9h-9v-5h9v5z" />
    </svg>
  )
}

export default CreditCardSvg
