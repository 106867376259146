import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {ruRU} from '@material-ui/core/locale';
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import EditIcon from '@material-ui/icons/Edit';
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      table: {
        minWidth: 650,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      paper: {
        padding: theme.spacing(1),
        margin: "auto",
        maxWidth: 350
      },
      title: {
        fontSize: 14,
      },
      buttons: {
        marginTop: "30px"
      },
      submitButton: {
        marginLeft: "20px"
      }
  
      
      
    
  }));

  const theme = createMuiTheme({
    palette: {
      primary: { main: '#1976d2' },
    },
  }, ruRU);


  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }

  TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
  };

  const ProfileCard = props => {
    const { history } = props;
    const classes = useStyles();
    const toggleFamilyReadonly = () => {
        setFamilyIsReadonly(!familyIsReadonly); 
    }
   
    
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhone] = useState('');
  const [emails, setEmails] = useState('');
  const [buttonsAllowed, setButtonsAllowed] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClickDialogOpen = () => { setDialogOpen(true); };
  const handleDialogClose = () => { setDialogOpen(false);props.history.push('/'); };

    React.useEffect(() => {
      if(!props.accessToken) history.push("/")
      setLastName(props.userCredentials.lastName);
      setFirstName(props.userCredentials.firstName);
      setPhone(props.userCredentials.phone);
      setEmails(props.userCredentials.emails);
  }, [props.userCredentials.lastName, props.userCredentials.firstName, props.userCredentials.phone, props.userCredentials.emails ])
    

    const handleLastNameInput = event => {
      setLastName(event.target.value);
      //alert(lastName);
      //alert(event.target.value);
      let act = lastName !== event.target.value;
      setButtonsAllowed(act);
    };
    const handleFirstNameInput = event => {
      setFirstName(event.target.value);
      let act = firstName !== event.target.value;
      setButtonsAllowed(act);
    };
    const handlePhoneInput = event => {
      setPhone(event.target.value);
      let act = phone !== event.target.value;
      setButtonsAllowed(act);
    };
    
    const handleSubmit = event => {
      let objToSend = {};
      if(lastName != props.userCredentials.lastName ) objToSend.surname = lastName;
      if(firstName != props.userCredentials.firstName ) objToSend.givenName = firstName;
      if(phone != props.userCredentials.phone ) objToSend.extension_2a5146c87c79472d98f426db0b04c2b3_Phone = phone;
      let jsonString = JSON.stringify(objToSend);
      console.log('[ProfileCard/L137] jsonString=',jsonString)
      console.log('[ProfileCard/L138] props.accessToken=',props.accessToken)
      props.updateUserCredentials(jsonString, props.accessToken);
      setDialogOpen(true);
    };
    const handleReset = event => {
      //alert('Капсаицин!');
    };


    const [familyIsReadonly, setFamilyIsReadonly] = React.useState(true);


    const renderContent = () => {
        if(props.title == "Персональные данные")
        {
            return (
              <React.Fragment>
                
                <form className={classes.root} autoComplete="off">
                <TextField 
                    id="firstname" 
                    label="Имя" 
                    fullWidth 
                    size="small"
                    margin="normal"
                    required
                     value={firstName || ''}
                     onChange={handleFirstNameInput}
                    />
                    <TextField 
                    id="lastname" 
                    label="Фамилия" 
                    fullWidth 
                    size="small"
                    margin="normal"
                    required
                    onChange={handleLastNameInput}
                    value={lastName || ''}
                    />
                    <TextField 
                    id="phone" 
                    label="Номер телефона" 
                    fullWidth 
                    size="small"
                    margin="normal"
                    required
                    value={phone || ''}
                    onChange={handlePhoneInput}
                    /> 
                    {/* <Input
                    value={values.textmask}
                    onChange={handleChange}
                    name="textmask"
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom}
                    /> */}
                    <TextField 
                    id="email" 
                    label="Email" 
                    fullWidth 
                    size="small"
                    margin="normal"
                    disabled
                    required
                    value={emails || ''}
                    />
                    <Grid container justify="flex-end" className={classes.buttons}>
                    <Button variant="contained" color="default" onClick={handleReset} disabled={!buttonsAllowed}>Отмена</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton} disabled={!buttonsAllowed}>Сохранить</Button>     
                    </Grid>
                </form>
                </React.Fragment>
            )
        }

        if(props.title == "Адреса доставки")
        {
            return (
                <React.Fragment>
                      <Typography variant="body2" style={{ cursor: "pointer" }} color="primary" > Адресов доставки: {props.addressCount} </Typography>
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Данные сохранены"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Ваши данные успешно сохранены и через некоторое время они обновятся.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
    
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

               <Card className={classes.root} variant="outlined">
                <CardHeader subheader={props.title}>
                </CardHeader>   
                <CardContent>
                    {renderContent()}
                </CardContent>
                </Card>
        </React.Fragment>
    )

  }

  const mapStateToProps = state => {
    return {
        accessToken: state.auth.accessToken,
        userCredentials: state.graph.userCredentials,
        addressCount: state.profile.addressCount
    }
};

const mapDispatchToProps = dispatch => {
  return {
      updateUserCredentials: (json, accessToken) => dispatch(actions.updateUserCredentials(json, accessToken))
  };
};

  
  export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileCard));