import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import UserSubscriptionConfirmation from "./UserSubscriptionConfirmation";
import * as actions from '../../store/actions';


import ListForCustomerConfirmed from "./ListForConfirmedCustomer";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    input: {
      display: "qone",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 750,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: 224,
      maxHeight: 160,
    },
    imgPreview: {
      //margin: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      //display: "block",
      maxWidth: 180,
      maxHeight: 128,
    },
    cancelButton: {
      marginBottom: theme.spacing(5),
    },
    actionsContainer: {
      marginBottom: theme.spacing(4),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));

  const customerConfirmed = () => {
    return (
    <div>Сундучелло!</div>
    )
  }

  const TicketList = (props) => {
    const classes = useStyles();

    useEffect(() => {
      props.getUserClaims(props.userLogin)
  }, [])

    return (
        <React.Fragment>
            {props.whetherUserIsSubscribedToATicket ? <ListForCustomerConfirmed /> : <UserSubscriptionConfirmation />}
        </React.Fragment>
    )

  }

  const mapStateToProps = (state) => {
    return {
      customerFio: state.auth.userFio,
      customerPhone: state.auth.userPhone,
      confirmationResult: state.ticket.confirmationResult,
      customerId: state.ticket.customerId,
      objectId: state.auth.userId,
      userLogin: state.auth.userLogin,
      whetherUserIsSubscribedToATicket: state.profile.whetherUserIsSubscribedToATicket
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
        doTicketCheck: (ticketNumber, ticketSerial, customerLastName, customerFirstName) => dispatch(actions.doTicketCheck(ticketNumber, ticketSerial, customerLastName, customerFirstName)),
        resetConfirmationResult: () => dispatch(actions.resetConfirmationResult()),
        confirmUserAsValidTicketCustomer: (objectId, customerId) => dispatch(actions.confirmUserAsValidTicketCustomer(objectId, customerId)),
        getUserClaims: (userLogin) => dispatch(actions.getUserClaims(userLogin)),

    };
  };


  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(TicketList));