import * as React from "react"

function VerificationSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill={props.fill}
      viewBox="0 0 476 476"
      {...props}
    >
      <path d="M241.604 34h-10.472C225.536 34 221 29.464 221 23.868v-.34C221 10.533 210.467 0 197.472 0h-.544C183.933 0 173.4 10.533 173.4 23.528v.34c0 5.596-4.536 10.132-10.132 10.132h-10.472c-11.152 0-20.196 9.044-20.196 20.196v.408c0 11.152 9.044 20.196 20.196 20.196h88.808c11.152 0 20.196-9.044 20.196-20.196v-.408C261.8 43.044 252.756 34 241.604 34zM197.2 34c-5.63 0-10.2-4.569-10.2-10.2 0-5.63 4.57-10.2 10.2-10.2s10.2 4.569 10.2 10.2c0 5.631-4.569 10.2-10.2 10.2zm45.41 394.4H71.4c-15.021 0-27.2-12.179-27.2-27.2V74.8c0-15.021 12.179-27.2 20.4-27.2h55.107a34.88 34.88 0 00-.707 7.004c0 18.632 15.157 33.796 33.796 33.796h88.809c18.639 0 33.796-15.157 33.796-34.204 0-2.257-.245-4.46-.667-6.596H316.2c21.821 0 34 12.179 34 27.2v204.347c-2.258-.143-4.502-.347-6.8-.347-9.404 0-18.489 1.326-27.2 3.584V122.4h-238v272h156.747c.741 11.954 3.352 23.392 7.663 34zM343.4 299.2c-48.824 0-88.4 39.576-88.4 88.399 0 48.824 39.576 88.4 88.4 88.4 48.823 0 88.399-39.576 88.399-88.4.001-48.823-39.575-88.399-88.399-88.399zm57.766 70.238l-54.4 61.2a13.58 13.58 0 01-10.173 4.562c-2.984 0-5.983-.979-8.493-2.979l-34-27.2c-5.861-4.686-6.813-13.246-2.121-19.114 4.686-5.869 13.253-6.821 19.114-2.122l23.916 19.129 45.825-51.551c4.984-5.61 13.586-6.12 19.196-1.129s6.127 13.586 1.136 19.204z" />
    </svg>
  )
}

export default VerificationSvg
