import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import WithNumberFormat from '../../Hoc/WithNumberFormat';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as actions from '../../../store/actions';
import Poppins from 'typeface-poppins';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import OfferDialog from './OfferDialog';
import OfferHistoryDialog from '../OfferHistoryDialog/OfferHistoryDialog';
import Chat from './Chat';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 10,
    },
    paper: {
        padding: theme.spacing(4),
        margin: 'auto',
        maxWidth: 600,
    },
    image: {
        width: 200,
        height: 200,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    orangeText: {
        color: 'orange',
    },
    fab: {
        margin: 90,
        top: 70,
        right: 120,
        bottom: 120,
        left: 180,
        position: 'relative',
    },
    blueText: {
        color: 'blue',
    },
    blueTextBordered: {
        color: 'blue',
        border: '1px solid',
    },
    redColor: {
        backgroundColor: 'red',
        padding: '10px 10px',
    },
    yellowColor: {
        backgroundColor: 'yellow',
    },
    greenColor: {
        backgroundColor: 'green',
    },
    blueColor: {
        backgroundColor: 'blue',
    },
    orangeColor: offerType => {
        switch (offerType) {
            case 2:
                return {
                    backgroundColor: 'orange',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 1:
                return {
                    backgroundColor: '#2196f3',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 3:
                return {
                    backgroundColor: '#1ABC9C',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 4:
                return {
                    backgroundColor: 'grey',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
        }
    },
    infoColor: { backgroundColor: theme.palette.info.main, color: 'white' },
    successColor: { backgroundColor: theme.palette.success.main, color: 'white' },
}));

const fontWeightMedium = 700;
const themePoppins = createMuiTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', fontWeightMedium
        
      },
      palette: {
    
        primary: {
          main: '#1ABC9C'  // зелёный от MTWIN
        },
          secondary: {
              //main: '#2196f3',  //индиго от MUI
              main: '#757575', //тёмно-серый от Бори
              
          },
          error: {
              main: '#ff9100',  //оранжевый цвет
          },
      },
  })

const printTitleMessage = offerType => {
    switch (offerType) {
        case 1:
            return 'МОЁ ПРЕДЛОЖЕНИЕ';
        case 2:
            return 'ВСТРЕЧНОЕ ПРЕДЛОЖЕНИЕ';
        case 3:
            return 'ПРЕДЛОЖЕНИЕ СОГЛАСОВАНО';
        case 4:
            return 'ПРЕДЛОЖЕНИЕ ОТКЛОНЕНО';
    }
};

const ItemCard = props => {
    const { match } = props;
    const { params } = match;
    const { offerId } = params;
    const theme = useTheme();
    const matchesMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    const [acceptOfferDialogOpen, setAcceptOfferDialogOpen] = React.useState(false);
    const [historyDialogOpen, setHistoryDialogOpen] = React.useState(false);

    const handleClickOpen = () => {
        setAcceptOfferDialogOpen(true);
        //alert('сафа');
    };


    const closeAcceptOfferDialog = () => {
        setAcceptOfferDialogOpen(false);
    };

    const historyDialogClose = () => {
        setHistoryDialogOpen(false);
    };

    const handleAcceptOffer = () => {
        //alert(props.offer.offerId)
        props.acceptOffer(props.offer.offerId, props.environment);
        props.history.push('/offers');
    };

    const getCorrectTitleStyle = offerType => {
        switch (offerType) {
            case 3:
                return 'secondary';
            case 2:
                return 'secondary';
            case 1:
                return 'secondary';
            default:
                return 'initial';
        }
    };

    const getCorrectTitleStyleForThePrice = offerType => {
        
        switch (offerType) {
            case 3:
                return 'primary';
            case 2:
                return 'primary';
            case 1:
                return 'primary';
            default:
                return 'initial';
        }
    };

    const renderActionButtons = offerType => {
        console.log('[ItemCard](renderActionButtons) offerType=',offerType);
        switch (offerType) {
            case 2:
                return (
                    <Grid container style={{ padding: '10px 10px' }} justify='center' spacing={1}>
                        <Grid item key={1}>
                            {/* <Button variant='outlined' style={{ border: '#757575 2px solid' }} size='large' onClick={handleClickOpen}> */}
                            <Button variant='contained' style={{ backgroundColor: '#757575', color: 'white' }} size='large' onClick={handleClickOpen}>
                                Встречное
                            </Button>
                        </Grid>
                        <Grid item key={2}>
                            <Button variant='contained' style={{ backgroundColor: '#1ABC9C', color: 'white' }} size='large' onClick={handleAcceptOffer}>
                                Принять
                            </Button>
                        </Grid>
                    </Grid>
                );
            case 3:
                    return (
                        <Grid container style={{ padding: '10px 10px' }} justify='center' spacing={1}>
                            <Grid item key={1}>
                                <Button size='large' variant="contained" style={{ color: 'white' }} color="primary" onClick={handleAcceptOffer}>
                                    Купить
                                </Button>
                            </Grid>
                        </Grid>
                    );    
        }
    };

    useEffect(() => {
        console.log('[ItemCard.jsx] ooofffeeerrrid=',offerId)
        if(typeof props.offer.messageId == 'undefined') {console.log('[ItemCard.jsx] CCofferId=',offerId);props.getOffer(offerId);}
        else {
        //console.log('offerid=',offerId)
        //console.log('props.offer.messageId=',props.offer.messageId)

        const idx = props.alertsArray.findIndex(chr => chr.offerId == offerId && chr.priceOfferAlertCount > 0);
        //console.log('idx=',idx)
        if (idx > -1) {
           // console.log('props.offerккккккккккккккккк=', props.offer);
            let messageIds = [props.offer.messageId];
            props.setSeenBy(offerId, messageIds, 'PriceOfferMessage');
        }

        }

        

    }, [offerId,props.offer.messageId,props.alertsArray.length]);

    const classes = useStyles(props.offer.offerType);

    return (
        <React.Fragment>
            {/* <Breadcrumbs aria-label='breadcrumb'>
                <Link color='inherit' to='/' href='/'>
                    Главная страница
                </Link>
                <Link color='inherit' to='/offers' href='/offers'>
                    Все предложения цены
                </Link>
                <Typography color='textPrimary'>Карточка товара</Typography>
            </Breadcrumbs> */}

            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid container justify='center'>
                                <Grid item style={{ marginBottom: '10px' }} sm={7}>
                                    {/* <Chip variant="default" color="primary" label={printTitleMessage(props.offer.offerType)} className={classes.orangeColor} /> */}

                                    <ThemeProvider theme={themePoppins}>
                                    <Typography variant='h5' color={getCorrectTitleStyle(props.offer.offerType)}>
                                        {printTitleMessage(props.offer.offerType)}
                                    </Typography>
                                    </ThemeProvider>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px'}} sm={5}>
                                    <Typography variant='h6' color={getCorrectTitleStyleForThePrice(props.offer.offerType)} align='right'>
                                    
                                        <WithNumberFormat value={props.offer.offerPrice} digits={2} />
                                        &nbsp;₽
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Hidden only={['xs']}>
                            <Grid container >
                                <Grid item xs={7} style={{ marginBottom: '10px'}}>
                                    {/* <Chip variant="default" color="primary" label={printTitleMessage(props.offer.offerType)} className={classes.orangeColor} /> */}
                                    {/* <IconButton onClick={() => props.getOffer(offerId)} className={classes.menuButton} color='primary'>
                                        {' '}
                                        <RefreshIcon />{' '}
                                    </IconButton> */}
                                    <ThemeProvider theme={themePoppins}>
                                    <Typography variant='h6' display='inline' color={getCorrectTitleStyle(props.offer.offerType)}>
                                        {printTitleMessage(props.offer.offerType)}
                                    </Typography>
                                    </ThemeProvider>
                                </Grid>

                                <Grid item xs={5} style={{ marginBottom: '10px'}}>
                                    <Typography variant='h5' color={getCorrectTitleStyleForThePrice(props.offer.offerType)} align='right'>
                                        <WithNumberFormat value={props.offer.offerPrice} digits={2} />
                                        &nbsp;₽
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Hidden>

                        <Grid container>

                        <Grid item md={5} lg={5} xl={5} xs={12} sm={12}>
                            <ButtonBase className={classes.image}>
                              {typeof props.offer.pictureId == 'undefined' ? null : <img className={classes.img} alt='Изображение товара' src={'https://www.mtwin.ru/api/image/id/' + props.offer.pictureId + '/300/450'} />}
                            </ButtonBase>
                        </Grid>

                        <Grid item md={7} lg={7} xl={7} xs={12} sm={12}>
                            <Grid container direction='column' >
                                <Grid item xs={12} >
                                    <Typography variant='body1' gutterBottom align='right'>
                                    Предложение истекает {moment(props.offer.expiredDate).format('DD.MM.YYYY HH:mm')}
                                    </Typography>
                                    <Typography variant='h5' gutterBottom>
                                        {props.offer.lotName}
                                    </Typography>
                                    <ThemeProvider theme={themePoppins}>
                                    <Typography variant='body2' gutterBottom color='textSecondary'>
                                        КОД: {props.offer.internalNumber}
                                    </Typography>
                                    </ThemeProvider>

                                    <Typography variant='body2' gutterBottom>
                                        {props.offer.productDescription}
                                    </Typography>

                                    {/* <Button size="small" fullWidth variant="contained" className={classes.infoColor} onClick={() => {setHistoryDialogOpen(true)}}>История предложений</Button> */}

                                    <ButtonBase>
                                    <ThemeProvider theme={themePoppins}>
                                        <Typography
                                            variant='body2'
                                            style={{ cursor: 'pointer', fontWeight: 600 }}
                                            color='secondary'
                                            onClick={() => {
                                                setHistoryDialogOpen(true);
                                            }}>
                                            ИСТОРИЯ ПРЕДЛОЖЕНИЙ
                                        </Typography>
                                        </ThemeProvider>
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </Grid>
                        {renderActionButtons(props.offer.offerType)}

                        </Grid>


                        <Grid container style={{ marginTop: '10px', padding: '10px' }}>
                            <Grid item xs={12}>
                                <Chat></Chat>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
            {acceptOfferDialogOpen ? <OfferDialog open={acceptOfferDialogOpen} dialogClose={closeAcceptOfferDialog}></OfferDialog> : null}
            {historyDialogOpen ? (
                <OfferHistoryDialog historyDialogOpen={historyDialogOpen} historyDialogClose={historyDialogClose} offerId={props.offer.offerId}></OfferHistoryDialog>
            ) : null}

            <Fab onClick={() => props.getOffer(offerId)} color='primary' style={{ margin: 0, top: 'auto', right: 20, bottom: 30, left: 'auto', position: 'fixed', color: "white" }}>
                <RefreshIcon />
            </Fab>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        offer: state.offers.offer,
        timer: state.offers.timer,
        alertsArray: state.offers.alertsArray,
        environment: state.auth.environment,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOffer: offerId => dispatch(actions.getOffer(offerId)),
        acceptOffer: (offerId,env) => dispatch(actions.acceptOffer(offerId,env)),
        setSeenBy: (offerId, messageIds, messageType) => dispatch(actions.setSeenBy(offerId, messageIds, messageType)),
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemCard));
