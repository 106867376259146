import * as actionTypes from './actionTypes';

export const getAccessToken = () => {
    return {
      type: actionTypes.GET_ACCESS_TOKEN
    };
  };

  export const getAccessTokenSuccess = (token) => {
    return {
      type: actionTypes.GET_ACCESS_TOKEN_SUCCESS,
      token: token
    };
  };

  export const getGraphAccessToken = (ordinaryAccessToken) => {
    return {
      type: actionTypes.GET_GRAPH_ACCESS_TOKEN,
      ordinaryAccessToken: ordinaryAccessToken
    };
  };

  export const getGraphAccessTokenSuccess = (token) => {
    return {
      type: actionTypes.GET_GRAPH_ACCESS_TOKEN_SUCCESS,
      token: token
    };
  };


  export const getUserCredentials = () => {
    return {
      type: actionTypes.GET_USER_CREDENTIALS
    }
  }

  export const getUserCredentialsSuccess = (payLoad) => {
    return {
      type: actionTypes.GET_USER_CREDENTIALS_SUCCESS,
      payLoad: payLoad
    }
  }

  export const updateUserCredentials = (json, accessToken) => {
    return {
      type: actionTypes.UPDATE_USER_CREDENTIALS,
      json: json,
      accessToken: accessToken
    }
  }