import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../updateObject';

const initialState = {
    offerItems: [],
    chatItems: [],
    chatCount: 0,
    cartCount: 0,
    offer: {},
    timer: null,
    offerHistory: [],
    offerHistoryCount: 0,
    shopOffersCount: 0,
    myOffersCount: 0,
    acceptedOffersCount: 0,
    alertsCount: 0,
    alertsArray: [],
    offerIdForCountdown: null
};

// const getBargainsSuccess = ( state, action ) => {
//     const newItems = updateObject( action.bargainItems );
    
//     let toReturn = updateObject( state, { bargainItems: state.bargainItems.concat( newItems ) } );
//     //console.log('ewolutia=',toReturn.bargainItems[0])
//     return toReturn;
// };

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_OFFERS_SUCCESS: return {
            ...state,
            offerItems: action.offerItems,
            offersCount: action.offersCount
        }

        case actionTypes.GET_OFFERS_COUNT_SUCCESS: return {
            ...state,
            shopOffersCount: action.incomingOffersCount,
            myOffersCount: action.outgoingOffersCount,
            acceptedOffersCount: action.acceptedOffersCount
        }

        case actionTypes.GET_ALERTS_COUNT_SUCCESS: return {
            ...state,
            alertsCount: action.textMessageAlertCount
        }

        case actionTypes.GET_ALERTS_ARRAY_SUCCESS: return {
            ...state,
            alertsArray: action.alertsArray
        }

        case actionTypes.GET_CHAT_SUCCESS: return {
            ...state,
            chatItems: action.chatItems,
            chatCount: action.chatCount
        }


        case actionTypes.CLEAR_CHAT: 
        
        return {
            ...state,
            chatItems: [],
            chatCount: 0
        }
        case actionTypes.GET_CART_COUNT_SUCCESS: 
        
        return {
            ...state,
            cartCount: action.cartCount
        }

        case actionTypes.GET_OFFER_SUCCESS: return {
            ...state,
            offer: action.offer,
            offerIdForCountdown: action.offer.offerId
        }

        case actionTypes.CLEAR_OFFER: return {
            ...state,
            offer: {}
        }

        case actionTypes.GET_OFFERS_COUNT_BY_TYPES_SUCCESS: return {
            ...state,
            myOfferCount: action.myOfferCount,
            shopOfferCount: action.shopOfferCount
        }
        
        case actionTypes.OFFER_TIMER_COUNTDOWN: 
        //alert('action.offerId='+action.offerId+' state.offerIdForCountdown='+state.offerIdForCountdown)
        if(action.offerId == state.offerIdForCountdown) return {
            ...state,
            timer: action.timer
        }

        case actionTypes.GET_OFFER_HISTORY_SUCCESS: return {
            ...state,
            offerHistory: action.offerHistoryItems,
            offerHistoryCount: action.offerHistoryCount
        }

        default: return state;
    }
};

export default reducer; 