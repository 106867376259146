import { put, call, takeEvery, all } from 'redux-saga/effects';
import { signInAuthProvider } from '../../authProvider';
//import axios from '../../components/Profile/axios-profile';
import axios from '../../components/Bargain/axios-offers';
import queryString from 'query-string';

import * as actions from '../actions';

// export function* getAccessTokenSaga(action) {
//     try {
//         const response = yield signInAuthProvider.getAccessToken({ scopes: ['https://mtwin.onmicrosoft.com/CustomerAccountService/User.ReadWrite'] });
//         yield all([put(actions.getOffersCount())]);
//     } catch (error) {}
// }

// export function* getGraphAccessTokenSaga(action) {
//     try {
//         var url = 'http://localhost:44302/api/AzureGraph/GetAccessToken';

//         const config = { headers: { Authorization: `Bearer ${action.ordinaryAccessToken}` } };

//         const response = yield axios.get(url, config);
//         console.log('getGraphAccessTokenSaga@@@@@@@@@@@@@@@@@=', response.data);
//         yield put(actions.getGraphAccessTokenSuccess(response.data.access_token));
//     } catch (error) {
//         console.log('error=', error);
//     }
// }

export function* getUserClaimsSaga(action) {
    try {
        let url = 'GetAzureUser';
        
        var config = { params: {userLogin: action.userLogin} };
        //let objectToSend = { userLogin: action.userLogin};
        //alert(action.userLogin)
        const response = yield axios.get(url, config);
        console.log('бибаибоба',response.data)
       if(response.data.userAssignedToTicket) yield all([put(actions.confirmThatUserSubscribedToATicket(response.data)), put(actions.getTicketList(response.data.customerId)) ]); 
       else yield put(actions.confirmThatUserSubscribedToATicket(response.data));
    } catch (error) {}
}