import { createStore } from 'redux';
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import axios  from 'axios';

const initialState = {
    initializing: false,
    initialized: false,
    idToken: null,
    userId: null,
    userFio: null,
    userFirstName: null,
    userLastName: null,
    userPhone: null,
    userEmail: null,
    userLogin: null,
    accessToken: null,
    gotAccessToken: false,
    state: AuthenticationState.Unauthenticated,
    environment: "DEBUG",
    customerAccountServiceBaseUri: null,
    axiosOffers: null
 
}

const authReducer = (state = initialState, action) => {
    //console.log('Garamondo=',action);
    switch (action.type) {
        case AuthenticationActions.Initializing:
          console.log("[AuthenticationActions.Initializing]", action);
          return {
            ...state,
            initializing: true,
            initialized: false,
          };
        case AuthenticationActions.Initialized:
          console.log("[AuthenticationActions.Initialized]", action);
          return {
            ...state,
            initializing: false,
            initialized: true,
          };
        case AuthenticationActions.AcquiredIdTokenSuccess:
          console.log("[AuthenticationActions.AcquiredIdTokenSuccess]", action);
          return {
            ...state,
            idToken: action.payload,
          };
        case AuthenticationActions.AcquiredAccessTokenSuccess:
          console.log("[AuthenticationActions.AcquiredAccessTokenSuccess]", action);
          
          //axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
          ///////////////axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.accessToken}`;


          //axios.defaults.headers.common['Authorization'] = '';
          

          let environment = null;
          let customerAccountServiceBaseUri = null;

          if (window.location.href.indexOf("localhost") > -1) {
              environment = "DEBUG";
              //customerAccountServiceBaseUri = 'http://localhost:44302/';
              customerAccountServiceBaseUri = 'https://dev-ws.mtwin.ru/CustomerAccountService/';
          } else if (window.location.href.indexOf("dev") > -1) {
              environment = "DEV";
              customerAccountServiceBaseUri = 'https://dev-ws.mtwin.ru/CustomerAccountService/';
          } else {
              environment = "PROD";
              customerAccountServiceBaseUri = 'https://ws.mtwin.ru/CustomerAccountService/';
          }
        
          localStorage.setItem('customerAccountServiceBaseUri', customerAccountServiceBaseUri);
          localStorage.setItem('environment', environment);
          //alert(`Bearer ${action.payload.accessToken}`);
          localStorage.setItem('token', `Bearer ${action.payload.accessToken}`);

          customerAccountServiceBaseUri += 'api/CustomerAccount/'
          console.log('L72 customerAccountServiceBaseUri = ',customerAccountServiceBaseUri)
          const defaultOptions = { baseURL: customerAccountServiceBaseUri, headers: {'Content-Type': 'application/json'} };
          let instance = axios.create(defaultOptions);
          
          instance.interceptors.request.use(function (config) {
            const storedToken = `Bearer ${action.payload.accessToken}`;
            config.headers.Authorization =  storedToken ? storedToken : '';
            return config;
          });


          //console.log('instance in L82 = ', instance);


          return {
            ...state,
            accessToken: action.payload.accessToken,
            gotAccessToken: true,
            environment: environment,
            customerAccountServiceBaseUri: customerAccountServiceBaseUri,
            axiosOffers: instance
          };
        case AuthenticationActions.AcquiredAccessTokenError:
          console.log("[AuthenticationActions.AcquiredAccessTokenError]", action);
          return {
            ...state,
            accessToken: null,
            gotAccessToken: false
          };
        case AuthenticationActions.LoginSuccess:
          console.log("[AuthenticationActions.LoginSuccess]", action);
          localStorage.setItem('userId', action.payload.account.accountIdentifier);
          return {
            ...state,
            account: action.payload.account,
            userInfo: action.payload.account.idToken.given_name+' '+action.payload.account.idToken.family_name + '        тел.: ' + action.payload.account.idToken.extension_Phone,
            userFirstName: action.payload.account.idToken.given_name,
            userLastName: action.payload.account.idToken.family_name,
            userFio: action.payload.account.idToken.given_name[0]+'.'+action.payload.account.idToken.family_name,
            userPhone: action.payload.account.idToken.extension_Phone,
            userEmail: action.payload.account.idToken.emails[0],
            userLogin: action.payload.account.idToken.name,
            userId: action.payload.account.accountIdentifier,
            idToken: action.payload.jwtIdToken
          };
          
        case AuthenticationActions.LoginError:
        case AuthenticationActions.AcquiredIdTokenError:
        case AuthenticationActions.LogoutSuccess:
          return { ...state, idToken: null, accessToken: null, account: null };
        case AuthenticationActions.AuthenticatedStateChanged:
          console.log("[AuthenticationActions.AuthenticatedStateChanged]", action);
          return {
            ...state,
            state: action.payload,
          };
        default:
          return state;
      }
    

}

export default authReducer;