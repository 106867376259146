import React, { useEffect, useState } from 'react';
import { makeStyles, theme, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import Paper from '@material-ui/core/Paper';
import * as actions from '../../../store/actions';
import ImgsViewer from 'react-images-viewer';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 10,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 600,
    },
    image: {
        width: 200,
        height: 200,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const Chat = props => {
    useEffect(() => {
        console.log('[Chat.jsx] props.chat', props.chat);

        if (props.chat.length > 0) {
            console.log('[Chat.jsx] props.offer.offerId=', props.offer.offerId);
            let messageIds = [];
            console.log('[Chat.jsx] props.chat.length=', props.chat.length);
            for (let message of props.chat) {
                if (message.messageLogin !== props.userLogin) {
                    if (message.seenByList == null) messageIds.push(message.messageId);
                    else {
                        let idx = message.seenByList.findIndex(chr => chr.personLogin == props.userLogin);
                        console.log('[Chat.jsx] idx=', idx);
                        if (idx === -1) messageIds.push(message.messageId);
                    }
                }
            }
            if (messageIds.length > 0) {
                console.log('[Chat.jsx] messageIds=', messageIds);
                props.setSeenBy(props.offer.offerId, messageIds, 'TextMessage');
            }
        }
    }, [props.chat.length,props.offer.offerId]);

    const { match } = props;
    const { params } = match;
    const { offerId } = params;
    const [textMessage, setTextMessage] = React.useState('');
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);
    const [pict, setPict] = React.useState('');

    const textMessageChanged = event => {
        setTextMessage(event.target.value);
    };

    const sendTextMessage = () => {
        props.postTextMessage(props.offer.offerId, textMessage);
        setTextMessage('');
    };

    const getRightPicAddress = (picId) => {
        if(props.environment == 'PROD') return 'http://ws.mtwin.ru/AuctionService/api/image/GetImageById/' + picId + '/450/300';
        else return 'http://dev-ws.mtwin.ru/AuctionService/api/image/GetImageById/' + picId + '/450/300';
    }

    const getRightJustify = messageType => {
        let toReturn = '';
        if (messageType == 1) toReturn = 'flex-end';
        if (messageType == 2) toReturn = 'flex-start';
        return toReturn;
    };

    const getRealPaperColor = messageType => {
        let toReturn = 'white';
        //if (messageType == 1) toReturn = '#F5F5F5';
        //if (messageType == 2) toReturn = 'Snow';
        if (messageType == 1) toReturn = '#64fcd7';
        if (messageType == 2) toReturn = '#ededed';
        return toReturn;
    };

    const shuppka = pictureId => {
        console.log(pictureId);
        //setViewerIsOpen(true);
    };

    const getRightColorForUsername = messageType => {
        let toReturn = 'black';
        if (messageType == 1) toReturn = 'DimGray';
        if (messageType == 2) toReturn = 'DimGray';
        return toReturn;
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <ImgsViewer
                imgs={[{ src: pict }]}
                currImg={0}
                isOpen={viewerIsOpen}
                onClose={() => {
                    setViewerIsOpen(false);
                }}
                closeBtnTitle='закрыть просмотр'
            />

            <Grid container spacing={3} alignItems='flex-end' style={{ marginBottom: '30px' }}>
                <Grid item xs={10}>
                    <TextField
                        fullWidth={true}
                        autoFocus
                        color='secondary'
                        margin='dense'
                        id='textMessage'
                        multiline
                        placeholder='введите сообщение'
                        type='text'
                        value={textMessage}
                        onChange={textMessageChanged}
                    />
                </Grid>

                <Grid item xs={1}>
                    <IconButton color='secondary' component='span' onClick={sendTextMessage}>
                        <SendIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {props.chat.map(row => (
                <Grid container spacing={2} key={row.messageId} justify={getRightJustify(row.messageType)}>
                    <Grid item xs={7}>
                        <Paper elevation={6} className={classes.paper} style={{ backgroundColor: getRealPaperColor(row.messageType) }}>
                            <Grid container spacing={1}>
                                <Grid item xs style={{ textAlign: 'left', color: getRightColorForUsername(row.messageType) }}>
                                    <Typography variant='caption'>{row.messageFirstName + ' ' + row.messageLastName}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    {row.text ? (
                                        <Typography variant='body1'>{row.text}</Typography>
                                    ) : (
                                        <img
                                            className={classes.img}
                                            alt='some chat photo'
                                            onClick={() => {
                                                if(props.environment === 'PROD') setPict('http://ws.mtwin.ru/AuctionService/api/image/GetImageById/' + row.pictureId + '/1500/1000');
                                                else setPict('http://dev-ws.mtwin.ru/AuctionService/api/image/GetImageById/' + row.pictureId + '/1500/1000');
                                                setViewerIsOpen(true);
                                            }}
                                           
                                            src={getRightPicAddress(row.pictureId)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justify='flex-end'>
                                <Grid item xs={7} style={{ textAlign: 'right', color: getRightColorForUsername(row.messageType) }}>
                                    <Typography variant='caption'>{moment(row.messageDate).format('DD.MM.YYYY HH:mm')}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    return {
        chat: state.offers.chatItems,
        count: state.offers.chatCount,
        offer: state.offers.offer,
        userLogin: state.auth.userLogin,
        environment: state.auth.environment
    };
};

const mapDispatchToProps = dispatch => {
    return {
        postTextMessage: (offerId, textMessage) => dispatch(actions.postTextMessage(offerId, textMessage)),
        setSeenBy: (offerId, messageIds, messageType) => dispatch(actions.setSeenBy(offerId, messageIds, messageType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat));
