import {put, call, takeEvery, all} from 'redux-saga/effects';
import { signInAuthProvider } from '../../authProvider';
import axios from '../../components/Bargain/axios-offers';
import * as actions from "../actions";

export function* getUserAddressListSaga(action) {
    try {
      let userId = action.userId == null ? localStorage.getItem('userId') : action.userId;
      
      let url = 'GetCustomerOnlineAddresses/' + userId;
      
         const response = yield axios.get( url);
         let payLoad = response.data;
         payLoad.sort(function (a, b) { 
           return b.default - a.default;
          });
         console.log('paypaypayLoad=',payLoad)
         yield put(actions.getUserAddressListSuccess(payLoad));
    } catch (error) {
    }
  }

  export function* removeUserAddressSaga(action) {
  
    try {
      let url = 'RemoveCustomerOnlineAddresses/'+action.userId+'/'+action.addressId;

      const response = yield axios.post(url);
      yield put(actions.getUserAddressList(action.userId));

    } catch (error) {
    }
  }

  export function* sendSMSSaga(action) {
  
    try {
      
      let url = 'SendSMS/';
      let objectToSend = { customerFio: action.customerFio, customerPhone: action.customerPhone, confirmationNumber: action.confirmationNumber };
      const response = yield axios.post(url, objectToSend);
      yield put(actions.sendSMSSuccess(action.userId));

    } catch (error) {
    }
  }

  export function* setUserAddressAsDefaultSaga(action) {
  
    try {
      let url = 'SetCustomerOnlineCurrentAddress/'+action.userId+'/'+action.addressId;

      const response = yield axios.post(url);
      yield put(actions.getUserAddressList(action.userId));

    } catch (error) {
    }
  }

  export function* saveUserAddressSaga(action) {
  
    try {
      console.log('action===',action);
        let id = action.payLoad.id;
        let url;
        let response;
        if(action.payLoad.modus == 'edit') {
          url = 'RemoveCustomerOnlineAddresses/' + id + '/' + action.payLoad.addressId;

          response = yield axios.post(url);
        }

        url = 'SaveCustomerOnlineAddresses/'+id;
        
      response = yield axios.post(url, action.payLoad);
      yield put(actions.getUserAddressList(action.payLoad.id));

    } catch (error) {
    }
  }

  export function* saveUserVerificationInfoSaga(action) {
  
    try {
      console.log('action===',action);
      //let fileUploadObj = {customerId: action.customerId, customerLogin: action.customerLogin}
      //let data = { fileUploadObj: JSON.stringify(fileUploadObj), file: action.img1 };
      var fd = new FormData();
      fd.append('img1', action.img1);
      fd.append('img2', action.img2);
      fd.append('img3', action.img3);
      fd.append('customerId', action.customerId);
      fd.append('customerLogin', action.customerLogin);
      fd.append('customerFirstname', action.customerFirstname);
      fd.append('customerLastname', action.customerLastname);
      fd.append('customerPhone', action.userPhone);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      const url = 'SaveUserVerificationInfo';
        
      const response = yield axios.post(url, fd ,config);
      //yield put(actions.saveUserVerificationInfoSuccess());
      yield all([put(actions.saveUserVerificationInfoSuccess()),put(actions.SeeIfThisCustomerSentHisPhotosToVerification())]);

    } catch (error) {
    }
  }

  export function* seeIfThisCustomerSentHisPhotosToVerificationSaga(action) {
    try {
      let url = 'SeeIfThisCustomerSentHisPhotosToVerification/';
      
         const response = yield axios.get( url);
         let payLoad = response.data;
         yield put(actions.SeeIfThisCustomerSentHisPhotosToVerificationSuccess(payLoad));
    } catch (error) {
    }
  }
  