import * as actionTypes from '../actions/actionTypes';
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const initialState = {
  addressList: [],
  addressListCount: 0,
  whetherUserVerificationInfoExists: true,
  whetherUserIsSubscribedToATicket: true,
  userSuccessfullyVerified: false
  
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
      case actionTypes.GET_USER_ADDRESS_LIST_SUCCESS: 
      console.log("[GET_USER_ADDRESS_LIST_SUCCESS]", action);
      return {
          ...state,
          addressList: action.payload
      }

      case actionTypes.GET_WHETHER_USER_VERIFICATION_INFO_EXISTS_SUCCESS:
      return {
          ...state,
          whetherUserVerificationInfoExists: action.payload
      }

      case actionTypes.SAVE_USER_VERIFICATION_INFO_SUCCESS:
      return {
          ...state,
          userSuccessfullyVerified: true
      }
      
      case actionTypes.CONFIRM_THAT_USER_SUBSCRIBED_TO_TICKET:
        
        return {
            ...state,
            whetherUserIsSubscribedToATicket: action.isSubscribed
        }

      case AuthenticationActions.AcquiredIdTokenSuccess:
        
          return {
            ...state,
            whetherUserIsSubscribedToATicket: ('extension_MtWinCustomerId' in action.payload.idToken.claims)
          };

      default: return state;

    }
  }

    export default reducer;   