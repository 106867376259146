import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import * as actions from "../../store/actions";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import hashHistory from "../Shared/hashHistory";


import HomeSvg from "../UI/Icons/HomeSvg";
import CoinsSvg from "../UI/Icons/CoinsSvg";
import GavelSvg from "../UI/Icons/GavelSvg";
import CreditCardSvg from "../UI/Icons/CreditCardSvg";
import StoreSvg from "../UI/Icons/StoreSvg";
import TagsSvg from "../UI/Icons/TagsSvg";
import UserProfileSvg from "../UI/Icons/UserProfileSvg";
import UserAddressBookSvg from "../UI/Icons/UserAddressBookSvg";
import UserAddressSvg from "../UI/Icons/UserAddressSvg";
import VerificationSvg from "../UI/Icons/VerificationSvg";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MyDrawer = (props) => {
  const { history } = props;
  const location = useLocation();
  //console.log('[Drawer] location=',location)
  const isSelected = (whereAmI) => {
    return location.pathname === whereAmI;
  };

  useEffect(() => {
    props.getOffersCount();
  }, []);

  const classes = useStyles();
  const [openCommodities, setOpenCommodities] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleClick = () => {
    setOpenCommodities(!openCommodities);
  };
  const handleClickProfile = () => {
    setOpenProfile(!openProfile);
  };

  const StyledBadgeMyOfferCount = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 0,
      //marginRight: theme.spacing(2),
      opacity: 0.6,
      border: `3px solid ${theme.palette.background.paper}`,
      fontSize: "1.1em",
      fontWeight: "600",
      padding: "8px 8px",
      backgroundColor: "#2196f3",
    },
  }))(Badge);

  const StyledBadgeShopOfferCount = withStyles((theme) => ({
    badge: {
      right: 23,
      top: 0,
      //marginRight: theme.spacing(2),
      fontSize: "1.1em",
      fontWeight: "600",
      opacity: 0.6,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: "8px 8px",
      backgroundColor: "#f28800",
      color: "white",
    },
  }))(Badge);

  const StyledBadgeAcceptedOfferCount = withStyles((theme) => ({
    badge: {
      right: 46,
      top: 0,
      //marginRight: theme.spacing(2),
      fontSize: "1.1em",
      fontWeight: "600",
      opacity: 0.6,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: "8px 8px",
      backgroundColor: "#1ABC9C",
      color: "white",
    },
  }))(Badge);

  const drawerItemList = [
    {
      text: "Предложения цены",
      icon: <CompareArrowsIcon />,
      onClick: () => history.push("/"),
    },
    {
      text: "Настройки",
      icon: <SettingsIcon />,
      onClick: () => history.push("/settings"),
    },
  ];

  return (
    <React.Fragment>
      <List component="nav" className={classes.root}>
        <ListItem
          button
          onClick={() => history.push("/")}
          selected={isSelected("/")}
        >
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faHome} size="1x" /> */}
            <HomeSvg />
          </ListItemIcon>
          <ListItemText primary="Главная" />
        </ListItem>

        <ListItem
          button
          // onClick={() => history.push("/offers")}
          onClick={() => hashHistory.push("/offers")}
          selected={isSelected("/offers")}
        >
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faCoins} size="1x" /> */}
            <CoinsSvg width="1.7em" height="1.7em" />
          </ListItemIcon>
          <StyledBadgeShopOfferCount
            badgeContent={props.shopOffersCount}
            color="primary"
            overlap="rectangle"
            title="Предложения цены от магазина"
          >
            <StyledBadgeMyOfferCount
              badgeContent={props.myOffersCount}
              color="secondary"
              overlap="rectangle"
              title="Мои предложения цены"
            >
               <StyledBadgeAcceptedOfferCount
              badgeContent={props.acceptedOffersCount}
              color="secondary"
              overlap="rectangle"
              title="Принятые предложения цены"
            >

              <ListItemText primary="Предложение цены" />
              </StyledBadgeAcceptedOfferCount>
            </StyledBadgeMyOfferCount>
          </StyledBadgeShopOfferCount>
          
        </ListItem>

        <ListItem button onClick={() => history.push("/offers")}>
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faGavel} size="1x" /> */}
            <GavelSvg />
          </ListItemIcon>
          <ListItemText primary="Аукцион" />
        </ListItem>

        <ListItem button onClick={() => history.push("/ticketlist")}>
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faCreditCard} size="1x" /> */}
            <CreditCardSvg />
          </ListItemIcon>
          <ListItemText primary="Займы" />
        </ListItem>

        <ListItem button onClick={() => history.push("/offers")}>
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faStore} size="1x" /> */}
            <StoreSvg />
          </ListItemIcon>
          <ListItemText primary="Товары на реализации" />
        </ListItem>

        <ListItem button onClick={() => history.push("/offers")}>
          <ListItemIcon>
            {/* <FontAwesomeIcon icon={faTags} size="1x" /> */}
            <TagsSvg />
          </ListItemIcon>
          <ListItemText primary="История покупок" />
        </ListItem>

        <Divider />

        <ListItem button onClick={handleClickProfile}>
          <ListItemIcon>
            <UserProfileSvg />
          </ListItemIcon>
          <ListItemText primary="Профиль" />
          {openProfile ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProfile} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/profile")}
              selected={isSelected("/profile")}
            >
              <ListItemIcon>
                <UserAddressBookSvg />
              </ListItemIcon>
              <ListItemText primary="Мои данные" />
            </ListItem>

            {props.whetherUserVerificationInfoExists == false ? (
              <React.Fragment>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => history.push("/verify")}
                  selected={isSelected("/verify")}
                >
                  <ListItemIcon>
                    <VerificationSvg
                      width="1.8em"
                      height="1.8em"
                      fill="#1ABC9C"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Верификация" />
                </ListItem>
              </React.Fragment>
            ) : null}

            <ListItem
              button
              className={classes.nested}
              onClick={() => history.push("/address")}
              selected={isSelected("/address")}
            >
              <ListItemIcon>
                <UserAddressSvg />
              </ListItemIcon>
              <ListItemText primary="Адреса доставки" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOffersCount: () => dispatch(actions.getOffersCount()),
  };
};

const mapStateToProps = (state) => {
  return {
    incomingOffersCount: state.offers.incomingOffersCount,
    myOffersCount: state.offers.myOffersCount,
    shopOffersCount: state.offers.shopOffersCount,
    acceptedOffersCount: state.offers.acceptedOffersCount,
    whetherUserVerificationInfoExists:
      state.profile.whetherUserVerificationInfoExists,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyDrawer));
