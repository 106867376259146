import React, { Component, useState, useRef,useEffect } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from 'react-st-modal';
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    rotten: {
    flexGrow: 1
    },

    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    input: {
      display: "qone",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 750,
    },
    papir: {
      height: 50,
      width: 45
     
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: 224,
      maxHeight: 160,
    },

    textField: {
      minWidth: 300,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      
    },

    imgPreview: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      maxWidth: 180,
      maxHeight: 128,
    },
    cancelButton: {
      marginBottom: theme.spacing(5),
    },
    actionsContainer: {
      marginBottom: theme.spacing(4),
    },
    submitButton: {
        marginLeft: "0px",
        marginTop: "40px"
      },
      
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));


  const ConfirmationByPinFunctionalDialog = (props) => {

    const {pin} = props;
    console.log('from L99 confirmationResult=',pin)  
    const classes = useStyles();
    const [input1Val, setInput1Val] = useState("")
    const [input2Val, setInput2Val] = useState("")
    const [input3Val, setInput3Val] = useState("")
    const [input4Val, setInput4Val] = useState("")
    const [input5Val, setInput5Val] = useState("")

    const textRef1 = useRef();
    const textRef2 = useRef();
    const textRef3 = useRef();
    const textRef4 = useRef();
    const textRef5 = useRef();

const finalize = (lastLetter) => {
    console.log('from L114 confirmationResult=',pin)
    const result = input1Val+input2Val+input3Val+input4Val+lastLetter;

    console.log('result=',result);
    console.log('pin=',pin);

    if(+result === +pin) {
        props.dialogClose();
        Alert('Идентификация пользователя ломбарда прошла успешно!','')
    }
    
}

    return (
        <Dialog
        open={props.open}
        onClose={props.dialogClose}
        pin={props.pin}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Введите код подтверждения из СМС"}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
        
        <Grid item xs={12}>
         <Grid container justifycontent="center" spacing={2}>
         <Grid item><TextField autoFocus value={input1Val} onChange={(e)=>{const val = e.target.value;setInput1Val(val);if (val.length===1) textRef2.current.focus();}} inputRef={textRef1} inputProps={{ maxLength: 1, style: {fontSize: 24}}}  size="small"  className={classes.papir}  margin="normal" variant='outlined' /></Grid>
         <Grid item><TextField value={input2Val} onChange={(e)=>{const val = e.target.value;setInput2Val(val);if (val.length===1) textRef3.current.focus();}} inputRef={textRef2} inputProps={{ maxLength: 1, style: {fontSize: 24}}}  size="small"  className={classes.papir}  margin="normal" variant='outlined' /></Grid>
         <Grid item><TextField value={input3Val} onChange={(e)=>{const val = e.target.value;setInput3Val(val);if (val.length===1) textRef4.current.focus();}} inputRef={textRef3} inputProps={{ maxLength: 1, style: {fontSize: 24}}}  size="small"  className={classes.papir}  margin="normal" variant='outlined' /></Grid>
         <Grid item><TextField value={input4Val} onChange={(e)=>{const val = e.target.value;setInput4Val(val);if (val.length===1) textRef5.current.focus();}} inputRef={textRef4} inputProps={{ maxLength: 1, style: {fontSize: 24}}}  size="small"  className={classes.papir}  margin="normal" variant='outlined' /></Grid>
         <Grid item><TextField value={input5Val} onChange={(e)=>{const val = e.target.value;setInput5Val(val);if (val.length===1) finalize(e.target.value);}} inputRef={textRef5} inputProps={{ maxLength: 1, style: {fontSize: 24}}}  size="small"  className={classes.papir}  margin="normal" variant='outlined' /></Grid>
         


        </Grid>
        </Grid>
        </Grid>
        </DialogContent>
        </Dialog>
    )
  }

  const mapDispatchToProps = dispatch => {
    return {
       // confirmThatUserSubscribedToATicket: () => dispatch(actions.confirmThatUserSubscribedToATicket())
    };
};

export default connect(null, mapDispatchToProps)(ConfirmationByPinFunctionalDialog); 
  