import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import ProfileCard from './ProfileCard/ProfileCard';
import AllAddressesCard from './AddressList/AddressList';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import * as actions from '../../store/actions/index';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import moment from 'moment';
import TablePagination from "@material-ui/core/TablePagination";
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {ruRU} from '@material-ui/core/locale';
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from '@material-ui/core/Container';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      table: {
        minWidth: 650,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      paper: {
        padding: theme.spacing(1),
        margin: "auto",
        maxWidth: 350
       
      },
      image: {
        width: 80,
        height: 80
      }
  
      
      
    
  }));



  const Profile = (props) => {
    const classes = useStyles();

    useEffect(() => { 
      //console.log("props.graphToken==",props.graphToken);
      //console.log("props.accessToken=",props.accessToken);
      props.getUserCredentials(); }, [])
    
    return (
        <React.Fragment>
          
            <Container fixed>
            <Grid container justify="space-evenly" spacing={4}>
                <Grid item sm={12} md={6} >
                    <ProfileCard title="Персональные данные" />
                </Grid>
                {/* <Grid item sm={12} md={6} >
                <ProfileCard title='Адреса доставки'/>
                </Grid> */}
            </Grid>
            </Container>
        </React.Fragment>

    )
  }

  const mapStateToProps = state => {
    return {
        accessToken: state.auth.accessToken,
        userCredentials: state.graph.userCredentials
    }
};


  const mapDispatchToProps = dispatch => {
    return {
        getUserCredentials: ( ) => dispatch(actions.getUserCredentials())
    };
};


export default  connect(mapStateToProps, mapDispatchToProps)(Profile);
  