import { takeEvery } from "redux-saga/effects";
import { clearCustomerTicketSubscription, counterOffer, getIncomingOffersCountSuccess } from "../actions";

import * as actionTypes from "../actions/actionTypes";

import { getOffersSaga } from './offers';
import { getOfferSaga } from './offers';
import { getChatSaga } from './offers';
import {getOfferHistorySaga} from './offers';
import {counterOfferSaga} from './offers';
import {acceptOfferSaga} from './offers';
import {declineOfferSaga} from './offers';
import {postTextMessageSaga} from './offers';
import {setSeenBySaga} from './offers';
import {getAccessTokenSaga} from './auth';
import {getGraphAccessTokenSaga} from './auth';
import {getUserCredentialsSaga} from './auth';
import {getOffersCountSaga} from './offers';
import {getAlertsCountSaga} from './offers';
import {getCartCountSaga} from './offers';
import {updateUserCredentialsSaga} from './auth';
import {getUserAddressListSaga} from './profile';
import {removeUserAddressSaga} from './profile';
import {saveUserAddressSaga} from './profile';
import {setUserAddressAsDefaultSaga} from './profile';
import {saveUserVerificationInfoSaga} from './profile';
import {seeIfThisCustomerSentHisPhotosToVerificationSaga} from './profile';
import {sendSMSSaga} from './profile';
import {doTicketCheckSaga} from './ticket';
import {confirmUserAsValidTicketCustomerSaga} from './ticket';
import {clearCustomerTicketSubscriptionSaga} from './ticket';
import { getTicketListSaga } from "./ticket";
import { getTicketSaga } from "./ticket";
import { getUserClaimsSaga } from "./azure";

export function* watchOffers() {
  yield takeEvery(actionTypes.GET_OFFERS, getOffersSaga);
  yield takeEvery(actionTypes.GET_OFFER, getOfferSaga);
  yield takeEvery(actionTypes.GET_CHAT, getChatSaga);
  yield takeEvery(actionTypes.GET_OFFER_HISTORY, getOfferHistorySaga);
  yield takeEvery(actionTypes.COUNTER_OFFER, counterOfferSaga);
  yield takeEvery(actionTypes.ACCEPT_OFFER, acceptOfferSaga);
  yield takeEvery(actionTypes.DECLINE_OFFER, declineOfferSaga);
  yield takeEvery(actionTypes.POST_TEXT_MESSAGE, postTextMessageSaga);
  yield takeEvery(actionTypes.SET_SEEN_BY, setSeenBySaga);
  yield takeEvery(actionTypes.GET_OFFERS_COUNT, getOffersCountSaga);
  yield takeEvery(actionTypes.GET_ALERTS_COUNT, getAlertsCountSaga);
  yield takeEvery(actionTypes.GET_CART_COUNT, getCartCountSaga);

  yield takeEvery(actionTypes.GET_ACCESS_TOKEN, getAccessTokenSaga);
  yield takeEvery(actionTypes.GET_GRAPH_ACCESS_TOKEN, getGraphAccessTokenSaga);
  yield takeEvery(actionTypes.GET_USER_CREDENTIALS, getUserCredentialsSaga);
  yield takeEvery(actionTypes.UPDATE_USER_CREDENTIALS, updateUserCredentialsSaga);

  yield takeEvery(actionTypes.GET_USER_ADDRESS_LIST, getUserAddressListSaga);
  yield takeEvery(actionTypes.REMOVE_USER_ADDRESS, removeUserAddressSaga);
  yield takeEvery(actionTypes.SET_USER_ADDRESS_AS_DEFAULT, setUserAddressAsDefaultSaga);
  yield takeEvery(actionTypes.SAVE_USER_ADDRESS, saveUserAddressSaga);
  yield takeEvery(actionTypes.SAVE_USER_VERIFICATION_INFO, saveUserVerificationInfoSaga);
  yield takeEvery(actionTypes.GET_WHETHER_USER_VERIFICATION_INFO_EXISTS, seeIfThisCustomerSentHisPhotosToVerificationSaga);
  yield takeEvery(actionTypes.SEND_SMS, sendSMSSaga);

  yield takeEvery(actionTypes.DO_TICKET_CHECK, doTicketCheckSaga);
  yield takeEvery(actionTypes.CONFIRM_USER_AS_VALID_TICKET_CUSTOMER, confirmUserAsValidTicketCustomerSaga);
  yield takeEvery(actionTypes.CLEAR_CUSTOMER_TICKET_SUBSCRIPTION, clearCustomerTicketSubscriptionSaga );
  yield takeEvery(actionTypes.GET_TICKET_LIST, getTicketListSaga);
  yield takeEvery(actionTypes.GET_TICKET, getTicketSaga);

  yield takeEvery(actionTypes.GET_USER_CLAIMS, getUserClaimsSaga);
}
