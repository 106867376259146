import {put, call, takeEvery, all} from 'redux-saga/effects';
import { signInAuthProvider } from '../../authProvider';
import axios from '../../components/Bargain/axios-offers';
import * as actions from "../actions";

export function* doTicketCheckSaga(action) {
    try {
        let url = 'DoTicketCheck';
        let objectToSend = { ticketNumber: action.ticketNumber, ticketSerial: action.ticketSerial, customerLastName: action.customerLastName, customerFirstName: action.customerFirstName };
        var config = { params: objectToSend };
        
        const response = yield axios.get(url, config);
        console.log('[From ticket.js saga] L13=',response.data)
        
        if(!response.data) yield put(actions.doTicketCheckSuccess({confirmationResult: undefined, customerId: undefined, checkWasSuccessfull: false}));
        else yield put(actions.doTicketCheckSuccess({confirmationResult: response.data.confirmationResult, customerId: response.data.customerId, checkWasSuccessfull: true}));
       
        
    } catch (error) {}
}

export function* confirmUserAsValidTicketCustomerSaga(action) {
    try {
        let url = 'ConfirmUserAsValidTicketCustomer';
       
        let objectToSend = { objectId: action.objectId, customerId: action.customerId };
        const response = yield axios.post(url, objectToSend);
       
        yield put(actions.confirmThatUserSubscribedToATicket(true));
        
    } catch (error) {}
}

export function* clearCustomerTicketSubscriptionSaga(action) {
    try {
        let url = 'ClearCustomerTicketSubscription';
       
        let objectToSend = { objectId: action.objectId};
        const response = yield axios.post(url, objectToSend);
       
        yield put(actions.confirmThatUserSubscribedToATicket(false));
        
    } catch (error) {}
}

export function* getTicketListSaga(action) {
    try {
        let url = 'GetTicketList';
       
        let objectToSend = { customerId: action.customerId};
        var config = { params: objectToSend };
        
        const response = yield axios.get(url, config);
        console.log('[From ticket.js saga] L52=',response.data)
        for(let oneItem of response.data)
        {
            oneItem.thisRowIsSelected = false;
            if(oneItem.ticketNumber == 'ТИ00009414') oneItem.stateReadable = 'действующий';
            if(oneItem.ticketNumber == 'ТИ00009415') oneItem.stateReadable = 'просрочен';
            if(oneItem.ticketNumber == 'ТИ00009408') oneItem.stateReadable = 'на аукционе';
        }
        yield put(actions.getTicketListSuccess(response.data));
        
       
        
    } catch (error) {}
}

export function* getTicketSaga(action) {
    try {
        let url = 'GetTicket';
       
        let objectToSend = { ticketId: action.ticketId};
        var config = { params: objectToSend };
        
        const response = yield axios.get(url, config);
        console.log('[From ticket.js saga] L77=',response.data)
        yield put(actions.getTicketSuccess(response.data));
    } catch (error) {}
}