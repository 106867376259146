import React, { useEffect, useState } from 'react';
import { makeStyles, theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import * as actions from '../../../store/actions';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 10
    },
    paper: {
      padding: theme.spacing(4),
      margin: "auto",
      maxWidth: 600
    },
    image: {
      width: 200,
      height: 200
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%"
    },
    orangeText: {
      color: "orange"
    },
    blueText: {
      color: "blue"
    },
    blueTextBordered: {
      color: "blue",
      border: "1px solid"
    }
  }));

  const OfferDialog = (props) =>
  {
        const { open } = props;
        
        const [openDialog, setOpenDialog] = React.useState(open);
        const [err, setErr] = React.useState(false);
        const[helperText, setHelperText] = React.useState(undefined);
        const [offerPrice, setOfferPrice] = React.useState(undefined);
        console.log("[OfferDialog]openDialog=",openDialog)
        const handleClose = () => { setOpenDialog(false); };
        const classes = useStyles();

    

        

        const proceedWithBackend = () => {
          props.counterOffer(props.offer.offerId, offerPrice);
          props.dialogClose();
        }

        const valueChanged = (event) => {
          if(event.target.value <= props.offer.lastClientPrice) {
            setErr(true);
            setHelperText("Ваше предложение цены не может быть меньше предыдущего");
          }
          else {
            setErr(false);
            setOfferPrice(event.target.value);
            setHelperText(undefined);
          }

          //setOfferPrice(e.target.value);if(e.target.value <= props.offer.lastClientPrice) setErr(true); else setErr(false);}
        }

        return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.dialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Предложите свою цену</DialogTitle>
            <DialogContent>
          <DialogContentText>
          Ваше ценовое предложение будет принято к рассмотрению. В случае положительного решения наши сотрудники свяжутся с Вами.
          </DialogContentText>
          <form className={classes.root} noValidate autoComplete="off">
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
            <TextField
            margin="dense"
            id="lastClientPrice"
            label="ПРЕД. ПРЕДЛОЖЕНИЕ"
            type="number"
            disabled
            value={props.offer.lastClientPrice}
          />
           </Grid>

           <Grid item xs>
           <TextField 
            error={err}
            autoFocus
            margin="dense"
            id="offerPrice"
            label="ВАШЕ ПРЕДЛОЖЕНИЕ"
            type="number"
            value={offerPrice}
            //onChange={(e) => {setOfferPrice(e.target.value);if(e.target.value <= props.offer.lastClientPrice) setErr(true); else setErr(false);}}
            onChange={valueChanged}
            helperText={helperText}
        />

           </Grid>

          </Grid>


          
        
          </form>
          </DialogContent>
          <DialogActions>
          <Button onClick={proceedWithBackend} variant="contained" color="primary" disabled={err || typeof offerPrice == "undefined"}>
            Ok
          </Button>
        </DialogActions>
        </Dialog>
        </React.Fragment>
        )
  }

  const mapStateToProps = state => {
    return {
        offer: state.offers.offer,
        accountInfo: state.auth.idToken
    }
  };

  const mapDispatchToProps = dispatch => {
    return {
        counterOffer: (offerId, offerPrice) => dispatch(actions.counterOffer(offerId, offerPrice))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDialog);  
//export default OfferDialog;

