import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as actions from '../../store/actions';
import { Alert, Confirm } from 'react-st-modal';
import Paper from '@material-ui/core/Paper';

import ConfirmationByPinFunctionalDialog from "../Shared/ConfirmationByPinFunctionalDialog";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    rotten: {
    flexGrow: 1
    },

    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    input: {
      display: "qone",
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 750,
    },
    papir: {
      height: 50,
      width: 45
     
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: 224,
      maxHeight: 160,
    },

    textField: {
     // minWidth: 300,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      
    },

    imgPreview: {
      //margin: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      //display: "block",
      maxWidth: 180,
      maxHeight: 128,
    },
    cancelButton: {
      marginBottom: theme.spacing(5),
    },
    actionsContainer: {
      marginBottom: theme.spacing(4),
    },
    submitButton: {
        marginLeft: "0px",
        marginTop: "40px"
      },
      
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));


    const UserSubscriptionConfirmation = (props) => {
    const classes = useStyles();
    const [ticketNumber, setTicketNumber] = useState('');
    const [ticketSerial, setTicketSerial] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerFirstName, setCustomerFirstName] = useState('');
    const [buttonsAllowed, setButtonsAllowed] = useState('');
    const [confirmationNumberFromUser, setConfirmationNumberFromUser] = useState(undefined);
    const [confirmationResult, setConfirmationResult] = useState(undefined);
    
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => { setDialogOpen(true); };
    const handleDialogClose = async () => { 
      setDialogOpen(false); 
      const result = await Confirm('Делаем реальную привязку?','Только для теста:');
          
          if (result) {
            props.confirmUserAsValidTicketCustomer(props.objectId, props.customerId);
          } else {
            Alert('Ничего не делаем','');
          }



      
   };
    const handleDialogCancel = () => { setDialogOpen(false); };

    useEffect(() => {
      
      if(typeof props.checkWasSuccessfull !== 'undefined')
      {
        //setConfirmationResult(props.confirmationResult)

      
      if(!props.checkWasSuccessfull)
      {
      
        Alert('К сожалению, указанные Вами данные не позволяют идентифицировать Вас как пользователя ломбарда. Пожалуйста, введите данные правильно.','Неправильные данные:')
        props.resetConfirmationResult();

      }
      if(props.checkWasSuccessfull)
      {
      
        setDialogOpen(true)
       // d2Ref.current.focus();

      }

      //props.resetConfirmationResult();
    }
  }, [props.checkWasSuccessfull])

    const handleTicketNumber = event => {
        setTicketNumber(event.target.value);
   
        let act = ticketNumber !== event.target.value;
        setButtonsAllowed(act);
      };

      const handleTicketSerial = event => {
        setTicketSerial(event.target.value);
        let act = ticketSerial !== event.target.value;
        setButtonsAllowed(act);
      };
      const handleCustomerLastName = event => {
        setCustomerLastName(event.target.value);
        let act = customerLastName !== event.target.value;
        setButtonsAllowed(act);
      };
      const handleCustomerFirstName = event => {
        setCustomerFirstName(event.target.value);
        let act = customerFirstName !== event.target.value;
        setButtonsAllowed(act);
      };

    

      const doTicketCheck = event => {
          props.doTicketCheck(ticketNumber, ticketSerial, customerLastName, customerFirstName)
      };


    return (
        <React.Fragment>

          <ConfirmationByPinFunctionalDialog
          open={dialogOpen}
          dialogClose = {handleDialogClose}
          pin={props.confirmationResult}
          >

          </ConfirmationByPinFunctionalDialog>



            
            <Typography gutterBottom  variant="h6">Для управления займами просим подтвердить Вашу личность</Typography>
            <Typography gutterBottom variant="subtitle1">Пожалуйста, введите номер и серию последнего залогового билета, а также Вашу фамилию и имя. На номер телефона, указанный при оформлении этого залогового билета будет выслана СМС с кодом подтверждения. Просим ввести этот номер в соответствующем поле ниже.</Typography>
            
            <form className={classes.root} autoComplete="off">
            <Grid container direction="column" justifycontent="center" alignItems="center" spacing={1}>
           
            <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField 
                    id="ticketnumber" 
                    label="Номер залогового билета" 
                    className={classes.textField}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                     value={ticketNumber || ''}
                     onChange={handleTicketNumber}
                    />
            </Grid>

            <Grid item xs={4}> 
                <TextField 
                    id="ticketserial" 
                    label="Серия залогового билета" 
                    className={classes.textField}
                    size="small"
                    fullWidth
                    margin="normal"
                    required
                     value={ticketSerial || ''}
                     onChange={handleTicketSerial}
                    />
                    </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                    <Grid item xs={4}>
                  <TextField 
                    id="customerLastName" 
                    label="Фамилия на залоговом билете" 
                    className={classes.textField}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    value={customerLastName || ''}
                     onChange={handleCustomerLastName}
                    />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField 
                    id="customerFirstName" 
                    label="Имя на залоговом билете" 
                    className={classes.textField}
                    size="small"
                    margin="normal"
                    fullWidth
                    required
                    value={customerFirstName || ''}
                     onChange={handleCustomerFirstName }
                    />
                    </Grid>
                    </Grid>
                  
              </Grid>

              <Grid item xs className={classes.buttons}>
              <Button variant="contained" color="primary" onClick={doTicketCheck} className={classes.submitButton}>Отправить</Button>     
              
              </Grid>
             
            </form>
        </React.Fragment>
    )

  }

  const mapStateToProps = (state) => {
    return {
      customerFio: state.auth.userFio,
      customerPhone: state.auth.userPhone,
      confirmationResult: state.ticket.confirmationResult,
      customerId: state.ticket.customerId,
      objectId: state.auth.userId,
      checkWasSuccessfull: state.ticket.checkWasSuccessfull
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
        doTicketCheck: (ticketNumber, ticketSerial, customerLastName, customerFirstName) => dispatch(actions.doTicketCheck(ticketNumber, ticketSerial, customerLastName, customerFirstName)),
        resetConfirmationResult: () => dispatch(actions.resetConfirmationResult()),
        confirmUserAsValidTicketCustomer: (objectId, customerId) => dispatch(actions.confirmUserAsValidTicketCustomer(objectId, customerId)),
        

    };
  };



  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(UserSubscriptionConfirmation));