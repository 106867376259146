import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import IconButton from '@material-ui/core/IconButton';
import ConfirmDialog from '../../../Shared/ConfirmDialog';
import Tooltip from '@material-ui/core/Tooltip'
import * as actions from '../../../../store/actions';
import EditAddressDialog from './EditAddressDialog';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import StarIcon from '@material-ui/icons/Star';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';



const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      table: {
        minWidth: 650,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      paper: {
        padding: theme.spacing(1),
        margin: "auto",
        maxWidth: 350
        
      },
      title: {
        fontSize: 14,
      },
      buttons: {
        marginTop: "30px"
      },
      submitButton: {
        marginLeft: "20px"
      },
      card: {
          minHeight: 170
      },
      menuButton: {
        color: 'grey[800]',
        fontSize: '11px'
        },
     contentPart: {
      height: 150,
      [theme.breakpoints.up('700')]: {
          height: 120
      },
      [theme.breakpoints.up('900')]: {
        height: 130
    }
     },
     wholeCard: {
       height: 220,
       [theme.breakpoints.up('700')]: {
       height: 190
    },
    [theme.breakpoints.up('900')]: {
   
      height: 200
    }

     }   
  }));

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

  const AddressCard = props => {
    const { history } = props;
    const classes = useStyles();

    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [modus, setModus] = React.useState("edit");

    const deletePost = () => {
      props.removeUserAddress(props.userId, props.eachAddress.id);
    }

    const getCorrectAddress = () => {
      console.log("props.eachAddress====",props.eachAddress)
        let toReturn = '';
        if(props.eachAddress.zip) toReturn = toReturn + props.eachAddress.zip + ', ';
        if(props.eachAddress.region) toReturn = toReturn + props.eachAddress.region + ', ';
        if(props.eachAddress.district) 
        {
          if(props.eachAddress.district.indexOf('район') > -1) toReturn = toReturn + props.eachAddress.district + ', ';
          else toReturn = toReturn + props.eachAddress.district + ' район, ';
        }
        
        if(props.eachAddress.city) toReturn = toReturn + props.eachAddress.city + ', ';
        if(props.eachAddress.street) toReturn = toReturn + props.eachAddress.street + ', ';
        if(props.eachAddress.home) toReturn = toReturn + '' + props.eachAddress.home + ' ';
        if(props.eachAddress.corp) toReturn = toReturn + '-' + props.eachAddress.corp + ' ';
        if(props.eachAddress.flat) toReturn = toReturn + '-' + props.eachAddress.flat;
        return toReturn;
    }

    const handleStarClick = () => {
      if(!props.eachAddress.default) props.setUserAddressAsDefault(props.userId, props.eachAddress.id);
      
    }

    const getCorrectDescription = () => {
        let toReturn = '';
        if(props.eachAddress.description) toReturn = 'Комментарий к адресу: ' + props.eachAddress.description;
        return toReturn;
    }

    const [editAddressDialogOpen, setEditAddressDialogOpen] = React.useState(false);
    const closeEditAddressDialog = () => { setEditAddressDialogOpen(false); }
    const handleClickOpen = () => {
      setEditAddressDialogOpen(true);
     };
     const handleEveryAddressClick = () => {
      
      setEditAddressDialogOpen(true);
    }

    return (
        <React.Fragment >

              
              <ListItem button   >
                {/* <ButtonBase> */}
                <Tooltip title="Адрес доставки по умолчанию">
              <ListItemIcon onClick={handleStarClick} >
              <StarIcon style={{ color: props.eachAddress.default ? amber[500] : grey[500] }} />
              </ListItemIcon>
              </Tooltip>
              {/* </ButtonBase> */}
              <Tooltip title="Редактировать адрес доставки">
              <ListItemText primary={getCorrectAddress()} secondary={props.eachAddress.description} onClick={handleEveryAddressClick}/>
              </Tooltip>
              <ListItemSecondaryAction>
              <Tooltip title="Удалить адрес доставки">
                    <IconButton edge="end" aria-label="delete" onClick = {() => {setConfirmOpen(true)}}>
                      <DeleteIcon />
                    </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
              </ListItem>
              {props.idx < props.addressListCount - 1 ? <Divider variant="inset" component="li"  /> : null}
              
              

                {editAddressDialogOpen ? (<EditAddressDialog open={editAddressDialogOpen} modus={modus} addressToEdit={props.eachAddress} dialogClose={closeEditAddressDialog}></EditAddressDialog>) : null}        


                <ConfirmDialog
                title="Удалить этот адрес?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deletePost}
                >
                Вы уверены, что хотите удалить этот адрес доставки?
                </ConfirmDialog>

                
        </React.Fragment>
    )

  }

  const mapStateToProps = state => {
    return {
        userId: state.auth.userId
    }
};

const mapDispatchToProps = dispatch => {
  return {
      removeUserAddress: (userId, addressId) => dispatch(actions.removeUserAddress(userId, addressId)),
      setUserAddressAsDefault: (userId, addressId) => dispatch(actions.setUserAddressAsDefault(userId, addressId))
  };
};

  
  //export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressCard));
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressCard));