import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import * as actions from '../../store/actions';
import ButtonBase from "@material-ui/core/ButtonBase";
import { withRouter } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import {poppinsFont} from '../../FontsImported';
import { makeStyles, useTheme, ThemeProvider, theme, createMuiTheme } from '@material-ui/core/styles';
import GifSpinner from '../UI/Spinner/GifSpinner';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(3)
    },
    paper: {
      //padding: theme.spacing(4),
      margin: "auto",
           
    },
    textShadow: {
      color: "white",
      textShadow: "1px 1px #732372, 1px -1px #732372, -1px 1px #732372, -1px -1px #732372, 3px 3px 6px rgba(0,0,0,.5)"
    }
  }));

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 0,
      top: 10,
      border: `3px solid ${theme.palette.background.paper}`,
      padding: '15px 14px',
    },
  }))(Badge);

  

const Home = (props) =>
{

  
  useEffect(() => { 
    if(props.gotAccessToken) props.getOffersCount(); 
  },[])



  const moveToOffers = () => {
props.history.push('./offers')
  }

    const classes = useStyles;
    //const theme = useTheme();
    return (
      
    <React.Fragment>
       <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom align="center">Здравствуйте, {props.userFirstName}!</Typography>
        <Typography variant="h5" >Добро пожаловать в личный кабинет, где мы собрали все сервисы MtWin для Вашего удобства.</Typography> 
        <Typography variant="h5" >В дальнейшем мы будем постоянно совершенствовать эти сервисы и добавлять новые. </Typography>
        
        </CardContent>
        </Card>

    </ React.Fragment>
    )
    }

    const mapDispatchToProps = dispatch => {
      return {
        getOffersCount: () => dispatch(actions.getOffersCount())
      };
  };

    const mapStateToProps = state => {
      return {
          shopOffersCount: state.offers.shopOffersCount,
          gotAccessToken: state.auth.gotAccessToken,
          userFirstName: state.auth.userFirstName,
          customerAccountServiceBaseUri: state.auth.customerAccountServiceBaseUri
      }
  };    

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));

