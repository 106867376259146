import * as React from "react"

function MegaphoneFilledSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488.5 488.5"
      width={props.width}
      height={props.height}
      fill={props.fill}
      {...props}
    >
      <path d="M405.1 210.95v.4c0 7.5 6.1 13.7 13.7 13.7h56c7.5 0 13.7-6.1 13.7-13.7v-.4c0-7.5-6.1-13.7-13.7-13.7h-56c-7.6 0-13.7 6.1-13.7 13.7zM406.2 270.75l53 21.1c7.4 2.9 15.6-1 18.1-8.5 2.2-6.8-1.3-14.2-8-16.9l-53-21.1c-7.4-2.9-15.6 1-18.1 8.5-2.1 6.9 1.3 14.3 8 16.9zM151.8 132.15H56.3c-19 0-34.4 15.4-34.4 34.4v15.3H11.5c-6.4 0-11.5 5.2-11.5 11.5v43.1c0 6.4 5.2 11.5 11.5 11.5h10.4v13.2c0 19 15.4 34.4 34.4 34.4h8.6l39.3 130.8 5.9-1.4 19.9-4.7 5.9-1.4-15.6-123.3h31.5v-14.3c41.8 6.8 110.8 24.5 159 70.2.5 8.5 8 15.1 16.9 14.1 7.7-.9 13.3-8 13.3-15.8v-88.5c19.4-7.2 33.2-25.9 33.2-47.7 0-21.9-13.8-40.5-33.2-47.7v-87.8c0-7.8-5.5-14.9-13.3-15.8-8.9-1-16.3 5.6-16.9 14.1-48.2 45.7-117.2 63.4-159 70.2v-14.4zM459.3 130.45l-53 21.1c-6.7 2.7-10.2 10-8 16.9 2.4 7.5 10.7 11.5 18.1 8.5l53-21.1c6.7-2.7 10.2-10 8-16.9-2.5-7.5-10.8-11.4-18.1-8.5z" />
    </svg>
  )
}

export default MegaphoneFilledSvg
