import axios from 'axios';

const fetchMe = () => {

  let customerAccountServiceBaseUri = null;
  

  if (window.location.href.indexOf("localhost") > -1) {
  
    customerAccountServiceBaseUri = 'http://localhost:44302/';
  } else if (window.location.href.indexOf("dev") > -1) {
  
    customerAccountServiceBaseUri = 'https://dev-ws.mtwin.ru/CustomerAccountService/';
  } else {
  
    customerAccountServiceBaseUri = 'https://ws.mtwin.ru/CustomerAccountService/';
  }

  let uri = customerAccountServiceBaseUri + 'api/AzureGraph/';
    
  const defaultOptions = {
    baseURL: uri,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const storedToken = localStorage.getItem('token');
    //console.log('token from axios-profile.js===',storedToken);
    config.headers.Authorization =  storedToken ? storedToken : '';
    return config;
  });

  return instance;
};

export default fetchMe();