import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ItemForMobile from './ItemForMobile/ItemForMobile';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
      },
  }));




const Offers = (props) => {

//    const classes = useStyles;

    const [offerItems, setOfferItems] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [skip, setSkip] = useState(0);


    //useEffect(() => { props.getOfferItems('silos',10,0,props.accountInfo.idToken.name); }, [])
    useEffect(() => {
        console.log('offers.jsx L50'); 
        props.getOfferItems(500,0,props.axiosOffers); 
        props.clearOffer();
        props.clearChat();
        if(props.accountInfo) console.log('Account info from Offers.jsx=',props.accountInfo)
    }, [props.accountInfo])
    
 
    return (
        <React.Fragment>
            
            {/* <Breadcrumbs aria-label="breadcrumb">
                
                <Typography color="textPrimary">Все предложения цены</Typography>
            </Breadcrumbs> */}

        
        
                {props.offerItems.map((item,index) => {
                    const {lotName, internalNumber, offerPrice, offerDate, tradePrice, expiredDate, pictureId, offerId, offerType, isExpired} = item;
                    let idx = props.alertsArray.findIndex(chr => chr.offerId == offerId && chr.textMessageAlertCount > 0);
                    const showTextAlert = idx > -1;
                    idx = props.alertsArray.findIndex(chr => chr.offerId == offerId && chr.priceOfferAlertCount > 0);
                    const showPriceOfferAlert = idx > -1;
                    
                    
                        return (
                            
                            <ItemForMobile
                            pic={pictureId}
                            key={internalNumber}
                            lotName={lotName}
                            tradePrice={tradePrice}
                            offerPrice={offerPrice}
                            offerDate={offerDate}
                            expiredDate={expiredDate}
                            offerId={offerId}
                            internalNumber={internalNumber}
                            offerType={offerType}
                            showTextAlert={showTextAlert}    
                            showPriceOfferAlert={showPriceOfferAlert}  
                            isExpired={isExpired}  
                            />
                    )
                    })
                }


        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        offerItems: state.offers.offerItems,
        count: state.offers.offersCount,
        accountInfo: state.auth.idToken,
        axiosOffers: state.auth.axiosOffers,
        alertsArray: state.offers.alertsArray
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOfferItems: (limit,offset,axios) => dispatch(actions.getOffers(limit,offset,axios)),
        clearOffer: () => dispatch(actions.clearOffer()),
        clearChat: () => dispatch(actions.clearChat())

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);

