import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as actions from '../../store/actions';
import { signInAuthProvider } from '../../authProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOpencart } from '@fortawesome/free-brands-svg-icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import {faBell, faUser} from '@fortawesome/free-regular-svg-icons';
import ShoppingCartSvg from '../UI/Icons/ShoppingCartSvg';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuBurton: {
        marginRight: theme.spacing(2),
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '126',
        maxHeight: '64',
        flexGrow: 1,
        marginLeft: theme.spacing(1),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    cart: {
        marginRight: theme.spacing(1),
        // flexGrow: 1
    },
    title: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
    },
    appBar: {
        //backgroundColor: '#6C757D',
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

const StyledBadge = withStyles(theme => ({
    badge: {
        right: 35,
        top: 12,
        //marginRight: theme.spacing(2),
        opacity: 0.8,
        border: `3px solid ${theme.palette.background.paper}`,
        padding: '7px 7px',
        backgroundColor: '#1ABC9C',
    },
}))(Badge);

const StyledBadgeAlerts = withStyles(theme => ({
    badge: {
        right: 17,
        top: 14,
        //marginRight: theme.spacing(2),
        opacity: 0.8,
        border: `3px solid ${theme.palette.background.paper}`,
        padding: '7px 7px',
        backgroundColor: 'red',
    },
}))(Badge);

const AppHeader = props => {
    const classes = useStyles();
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setAnchorEl(null);
    };
    const location = useLocation();

    const moveToOffers = () => {
        props.getGraphAccessToken();
    };

    const goToCart = () => {
        let href = 'https://dev-public.mtwin.ru/CartAuthorized';
        
        if(props.environment === 'PROD') href = 'https://www.mtwin.ru/CartAuthorized';
        window.open(href, '_blank');
    };

    const getCorrectMtWinAddress = () => {
        if (window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("dev") > -1) return 'https://dev-public.mtwin.ru/indexauthorized';
        else return 'https://www.mtwin.ru/indexauthorized';
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const renderCart = () => {
        //let condition = (location.pathname == '/offers' || location.pathname.indexOf('itemcard') == 1) && props.cartCount > 0;
        let condition = props.cartCount > 0;

        let toReturn = undefined;
        if (condition)
            toReturn = (
                <StyledBadge badgeContent={props.cartCount} color='secondary' overlap='rectangle'>
                    <IconButton aria-controls='menu-appbar' edge='end' onClick={goToCart} color='inherit' className={classes.cart}>
                        {/* <ShoppingCartIcon /> */}
                        {/* <FontAwesomeIcon icon={ faShoppingCart} size="2x" /> */}
                        {/* <FontAwesomeIcon icon={faOpencart} size='2x' /> */}
                        <ShoppingCartSvg width='1.5em' height='1.5em' />
                    </IconButton>
                </StyledBadge>
            );
        if (!condition) toReturn = null;
        return toReturn;
    };

    const renderUserFio = () => {
        let env = '';
        if (props.environment !== 'PROD' && props.gotAccessToken) env = `/  ${props.environment}`;
        return (
            <Typography style={{ marginLeft: 10 }} variant='subtitle1' align='right' display='inline'>
                {' '}
                {props.userFio} {env}
            </Typography>
        );
        
    };

    // const moveToProfile = () => {
    //     setAnchorEl(null);
    //     props.history.push('/profile');
    // };


    return (
        <AzureAD provider={signInAuthProvider} forceLogin={true}>
            {({ login, logout, authenticationState }) => {
                return (
                    <React.Fragment>
                        <AppBar position='fixed' className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge='start' aria-label='open drawer' className={classes.menuButton} color='inherit' onClick={props.toggleDrawer}>
                                    {' '}
                                    <MenuIcon />{' '}
                                </IconButton>
                                <a href={getCorrectMtWinAddress()}>
                                <img className={classes.titleu} height={40} alt='логотип MtWin' src='/mt2.png' />
                                </a>

                                <Typography variant='h6' className={classes.title}></Typography>

                                {renderCart()}

                                {props.gotAccessToken && (
                                    <div>
                                        <StyledBadgeAlerts badgeContent={props.alertsCount} color='secondary' overlap='rectangle'>
                                        <IconButton aria-label='account of current user' aria-controls='menu-appbar' color='inherit'>
                                            <FontAwesomeIcon icon={faBell} size="1x" />
                                        </IconButton>
                                        </StyledBadgeAlerts>

                                        <IconButton aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleMenu} color='inherit'>
                                            <FontAwesomeIcon icon={faUser} size="1x" />
                                        </IconButton>
                                        <StyledMenu
                                            id='menu-appbar'
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            open={open}
                                            onClose={handleClose}>

                                            <StyledMenuItem onClick={logout} title="Выйти из своего аккаунта">
                                                <ListItemIcon>
                                                <FontAwesomeIcon icon={faSignOutAlt} size="1x" />
                                                </ListItemIcon>
                                                <ListItemText primary='Выход' />
                                            </StyledMenuItem>
                                        </StyledMenu>
                                        { renderUserFio() }
                                    </div>
                                )}
                            </Toolbar>
                        </AppBar>
                    </React.Fragment>
                );
            }}
        </AzureAD>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getGraphAccessToken: () => dispatch(actions.getGraphAccessToken()),
        //getOffersCount: () => dispatch(actions.getOffersCount())
    };
};

const mapStateToProps = state => {
    return {
        userFio: state.auth.userFio,
        userPhone: state.auth.userPhone,
        userEmail: state.auth.userEmail,
        userInfo: state.auth.userInfo,
        cartCount: state.offers.cartCount,
        alertsCount: state.offers.alertsCount,
        environment: state.auth.environment,
        gotAccessToken: state.auth.gotAccessToken,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppHeader));
