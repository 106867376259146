import * as actionTypes from './actionTypes';


export const doTicketCheck = (ticketNumber, ticketSerial, customerLastName, customerFirstName) => {
    return {
      type: actionTypes.DO_TICKET_CHECK,
      ticketNumber: ticketNumber,
      ticketSerial: ticketSerial,
      customerLastName: customerLastName,
      customerFirstName: customerFirstName
      
    };
  };

  export const getTicketList = (customerId) => {
    return {
      type: actionTypes.GET_TICKET_LIST,
      customerId: customerId
    };
  };

  export const getTicketListSuccess = (payload) => {
    return {
      type: actionTypes.GET_TICKET_LIST_SUCCESS,
      payload: payload
    };
  };

  export const getTicket = (ticketId) => {
    return {
      type: actionTypes.GET_TICKET,
      ticketId: ticketId
    };
  };

  export const clearSingleTicket = () => {
    return {
      type: actionTypes.CLEAR_SINGLE_TICKET,
    };
  };

  export const memorizeTicketList = (ticketList) => {
    return {
      type: actionTypes.MEMORIZE_TICKET_LIST,
      ticketList: ticketList
    };
  }

  export const getTicketSuccess = (payload) => {
    return {
      type: actionTypes.GET_TICKET_SUCCESS,
      payload: payload
    };
  };

  export const doTicketCheckSuccess = (payload) => {
    return {
      type: actionTypes.DO_TICKET_CHECK_SUCCESS,
      payload: payload      
    };
  };

  export const resetConfirmationResult = () => {
    return {
      type: actionTypes.RESET_CONFIRMATION_RESULT
    }
  }

  export const confirmUserAsValidTicketCustomer = (objectId, customerId) => {
    return {
      type: actionTypes.CONFIRM_USER_AS_VALID_TICKET_CUSTOMER,
      objectId: objectId,
      customerId: customerId 
    }
  }

  export const clearCustomerTicketSubscription = (objectId) => {
    return {
      type: actionTypes.CLEAR_CUSTOMER_TICKET_SUBSCRIPTION,
      objectId: objectId
    }
  }