import React, { useEffect, useState } from 'react';
import { makeStyles, theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import WithNumberFormat from '../../Hoc/WithNumberFormat';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      padding: 10
    },
    paper: {
      padding: theme.spacing(4),
      margin: "auto",
      maxWidth: 550
    },
    image: {
      width: 200,
      height: 200
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%"
    },
    orangeText: {
      color: "orange"
    },
    blueText: {
      color: "blue"
    },
    blueTextBordered: {
      color: "blue",
      border: "1px solid"
    },
    redColor: {
      backgroundColor: "red",
      padding: "10px 10px"
    },
    yellowColor: {
      backgroundColor: "yellow"
    },
    greenColor: {
      backgroundColor: "green"
    },
    blueColor: {
      backgroundColor: "blue"
    },
    orangeColor: {
      backgroundColor: "orange",
      textAlign: "right",
      padding: "10px 10px"
    }
    
  }));

  const getCorrectStyle = (offerType) => {
    switch(offerType)
    {
      case 4 : return 'grey';
      case 3 : return 'lime';
      case 2 : return 'orange';
      case 1 : return 'blue';
      default: return 'red'
    }
  }

  const OfferHistoryDialog = (props) =>
{
    
    const {offerId} = props;
    //console.log('silosofiaOfferHistorydialog=',offerId);
    useEffect(() => {props.getOfferHistory(107,0,'silos',offerId);},[]);

    //console.log("musica=",props)

    const proceedWithBackend = () => {
      //props.saveMyOffer
      props.historyDialogClose();

    }
    
    const classes = useStyles();
    return (
        <React.Fragment>

        <Dialog open={props.historyDialogOpen} onClose={props.historyDialogClose} aria-labelledby="form-dialog-title">
            {/* <DialogTitle id="form-dialog-title">Предложите свою цену</DialogTitle> */}
            <DialogContent>
          {/* <DialogContentText> */}
   


   
          <TableContainer component={'span'} size="small">
               <Table aria-label="simple table">
               <TableBody>
               {props.offerHistory.map((row) => (
                <TableRow key={row.messageId}>  
                <TableCell align="center">{moment(row.offerDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                <TableCell align="left" style={{color: getCorrectStyle(row.offerType) }}>{row.firstName+' '+row.lastName}</TableCell>
                  <TableCell align="right"><WithNumberFormat value={row.offerPrice} digits={2}/></TableCell>
                </TableRow>
               ))}
               </TableBody>

               </Table>
               </TableContainer>



          {/* </DialogContentText>
          <form className={classes.root} noValidate autoComplete="off">
          
         
        
          </form> */}
          </DialogContent>
          <DialogActions>
          <Button onClick={props.historyDialogClose} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
        </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
  return {
      accountInfo: state.auth.idToken,
      offerHistory: state.offers.offerHistory
  }
};

const mapDispatchToProps = dispatch => {
  return {
      getOfferHistory: (limit,offset,name, offerId) => dispatch(actions.getOfferHistory(limit,offset,name, offerId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferHistoryDialog)
