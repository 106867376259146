import React from 'react';
import NumberFormat from "react-number-format";

const WithNumberFormat = props => (
<NumberFormat 
    displayType={"text"} 
    thousandSeparator={" "} 
    decimalSeparator={","} 
    decimalScale={props.digits} 
    fixedDecimalScale
    value={props.value}
/>
)

export default WithNumberFormat;