import * as React from "react"

function StoreSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="1.7em"
      height="1.7em"
      {...props}
    >
      <path d="M507.466 206.438L463.984 6.421A8.153 8.153 0 00456.017 0H55.983a8.153 8.153 0 00-7.967 6.421L4.534 206.438a8 8 0 00-.185 1.791l.001.069c.063 20.831 15.035 38.212 34.783 42.024v175.26c0 4.504 3.65 8.153 8.153 8.153s8.153-3.649 8.153-8.153v-175.26c10.904-2.106 20.347-8.346 26.633-17.024 7.808 10.779 20.488 17.81 34.786 17.81 14.298 0 26.977-7.031 34.786-17.81 7.808 10.779 20.488 17.81 34.786 17.81s26.977-7.031 34.786-17.81c7.808 10.779 20.488 17.81 34.786 17.81 14.298 0 26.977-7.031 34.786-17.81 7.808 10.779 20.488 17.81 34.786 17.81s26.977-7.031 34.786-17.81c7.808 10.779 20.488 17.81 34.786 17.81s26.977-7.031 34.786-17.81c6.285 8.677 15.728 14.919 26.633 17.024v36.117a8.151 8.151 0 008.153 8.153 8.151 8.151 0 008.153-8.153v-36.117c19.747-3.812 34.719-21.191 34.782-42.021a8.39 8.39 0 00-.187-1.863zM47.287 234.803c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.513 18.48-25.356 18.48zm27.627-34.786H22.617L62.554 16.306h36.322L74.914 200.017zm41.944 34.786c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.513 18.48-25.356 18.48zm27.311-34.786H91.358L115.32 16.306h44.823l-15.974 183.711zm42.26 34.786c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.513 18.48-25.356 18.48zm26.633-34.786h-52.525l15.974-183.711h36.551v183.711zM256 234.803c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.513 18.48-25.356 18.48zm26.633-34.786h-53.265V16.306h53.265v183.711zm42.938 34.786c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.512 18.48-25.356 18.48zm-26.633-34.786V16.306h36.551l15.974 183.711h-52.525zm96.204 34.786c-11.843 0-21.905-7.769-25.357-18.48h50.713c-3.451 10.71-13.512 18.48-25.356 18.48zm-27.311-34.786L351.857 16.306h44.823l23.962 183.711h-52.811zm45.293-183.711h36.322l39.937 183.711h-52.297L413.124 16.306zm51.589 218.497c-11.843 0-21.905-7.769-25.356-18.48h50.713c-3.452 10.71-13.513 18.48-25.357 18.48z" />
      <path d="M429.928 365.248h-9.24v-96.204a8.151 8.151 0 00-8.153-8.153H273.393a8.151 8.151 0 00-8.153 8.153v96.204H256c-4.503 0-8.153 3.649-8.153 8.153s3.65 8.153 8.153 8.153h173.928c4.504 0 8.153-3.649 8.153-8.153s-3.65-8.153-8.153-8.153zm-25.546 0H281.546v-88.051h122.837v88.051zM177.732 339.159c-14.086 0-25.546 11.46-25.546 25.546s11.46 25.546 25.546 25.546 25.546-11.46 25.546-25.546-11.46-25.546-25.546-25.546zm0 34.786c-5.095 0-9.24-4.145-9.24-9.24s4.145-9.24 9.24-9.24c5.095 0 9.24 4.145 9.24 9.24s-4.145 9.24-9.24 9.24z" />
      <path d="M499.499 495.694h-26.633V321.219a8.151 8.151 0 00-8.153-8.153 8.151 8.151 0 00-8.153 8.153v174.476H229.367v-226.65a8.152 8.152 0 00-8.153-8.153H82.072a8.152 8.152 0 00-8.153 8.153v226.65h-18.48v-35.328c0-4.504-3.65-8.153-8.153-8.153s-8.153 3.649-8.153 8.153v35.328H12.501c-4.503 0-8.153 3.649-8.153 8.153S7.999 512 12.501 512h486.998c4.504 0 8.153-3.649 8.153-8.153s-3.649-8.153-8.153-8.153zm-286.437 0H90.225V277.197h122.837v218.497z" />
      <path d="M383.515 324.155a8.157 8.157 0 00-11.531-.001l-17.393 17.393c-2.968 2.969-3.165 7.871-.433 11.063 3.048 3.561 8.647 3.782 11.964.469l17.393-17.393a8.154 8.154 0 000-11.531zM374.819 289.368a8.155 8.155 0 00-11.53 0l-52.178 52.178c-2.968 2.969-3.165 7.871-.433 11.063 3.048 3.561 8.647 3.782 11.964.469L374.82 300.9a8.158 8.158 0 00-.001-11.532z" />
    </svg>
  )
}

export default StoreSvg
