import { put, call, takeEvery, all } from 'redux-saga/effects';
import axios from '../../components/Bargain/axios-offers';
import { eventChannel, END } from 'redux-saga';
import * as actions from '../actions';
import moment from 'moment';

export function* getOffersSaga(action) {
    try {
        console.log('[offers.js/saga] in getOffersSaga ', action.limit);
        let url = 'GetOffersList?limit=' + action.limit + '&offset=' + action.offset;

        const response = yield axios.get(url);
        console.log('[offers.js/saga] L18 response=', response);
        const allItems = response.data.content;

        console.log('[offers.js/saga] allItems=', allItems);
        let filteredItems = [];

        let myOfferCount = 0;
        let shopOfferCount = 0;
        let acceptedOfferCount = 0;

        for (let val of allItems) {
            let idx = filteredItems.findIndex(chr => chr.internalNumber === val.internalNumber);
            
            //if ((idx === -1) && (val.offerType !== 4) && val.offerStatus =='Active' ) {
                if ((idx === -1) && (val.offerType !== 4) && (val.offerStatus =='Active' || val.offerStatus =='AcceptRequest') ) {
                    
                    //val.isExpired = moment(val.expiredDate).isBefore(moment());
                    console.log('[offers-saga L25 val=',val)
                filteredItems.push(val);
                if (val.offerType === 1) myOfferCount++;
                if (val.offerType === 2) shopOfferCount++;
                if (val.offerType === 3) acceptedOfferCount++;
            }
        }
        console.log('[offers.js/saga] myoffercount=', myOfferCount);
        console.log('[offers.js/saga] shopOffercount=', shopOfferCount);
        console.log('[offers.js/saga] acceptedOffercount=', acceptedOfferCount);
        console.log('[offers.js/saga] filtered items=', filteredItems);

        
        yield all([put(actions.getOffersSuccess(filteredItems, response.data.count)), put(actions.getCartCount())]);
        //yield all([put(actions.getOffersSuccess(filteredItems, response.data.count)), put(actions.getCartCount()), put(actions.confirmUserAsValidTicketCustomer('19a42ac7-7cff-4d64-af92-4eaac25699ae',''))]);
    } catch (error) {}
}

export function* getOfferSaga(action) {
    try {
        //console.log('in getIncomingOffersCountSaga ', action.axios.defaults.baseURL)
        let url = 'GetOffer/' + action.offerId;
        const response = yield axios.get(url);
        const offer = response.data;
        console.log('[offers.js/saga] offer in saga=',offer)
        yield all([put(actions.getOfferSuccess(offer)), put(actions.getChat(offer.offerId))]);
        if (offer.offerType == 2) {
            const remains = getTimeRemaining(offer.expiredDate);
            const whatRemainsObject = getTimeRemaining(offer.expiredDate);
            const channel = yield call(countdown, Math.round(whatRemainsObject.total / 1000));
            yield takeEvery(channel, function* (secs) {
                const whatRemainsObject = getTimeRemaining(offer.expiredDate);

                if (whatRemainsObject.total >= 1001) {
                    const timer = whatRemainsObject.hours + ':' + whatRemainsObject.minutes + ':' + whatRemainsObject.seconds;
                    yield put(actions.offerTimerCountdown(timer,offer.offerId));
                } else yield all([put(actions.offerTimerCountdown('',offer.offerId))]); //else yield all([put(actions.offerTimerCountdown('',offer.offerId)), put(actions.declineOffer(offer.offerId))]);
            });
        }

        //console.log('чёрная пакость offer L66=',offer)
        //yield all([put(actions.getOfferSuccess(offer)), put(actions.getChat(offer.offerId))]);
    } catch (error) {}
}

export function* getChatSaga(action) {
    try {
        
        if(!action.offerId) return;
        let url = 'GetChat/' + action.offerId;
        let objectToSend = { limit: action.limit, offset: action.offset };
        const response = yield axios.get(url, objectToSend);
        const chat = response.data.content;
        console.log('offers.js(Saga) L76, response.data.content = ',response.data.content)
        let toReturn = [];
        for(let message of chat)
        {
            console.log('ладаладалада=',message)
            if(message.text || message.pictureId) toReturn.push(message);
        }

        console.log('offers.js(Saga) L83, Chat = ',toReturn)
        yield put(actions.getChatSuccess(toReturn, toReturn.count));
        //yield put(actions.getChatSuccess(chat, 1));
    } catch (error) {}
}

export function* getOffersCountSaga(action) {
    try {
        let url = 'GetOffersCount';
        const response = yield axios.get(url);
        console.log('[offers.js/saga] responseoffers!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!=', response.data);
        yield all([put(actions.getOffersCountSuccess(response.data.incomingOfferCount, response.data.outgoingOfferCount, response.data.acceptedOfferCount)), put(actions.getCartCount()), put(actions.getAlertsCount() )]);
    } catch (error) {}
}

export function* getAlertsCountSaga(action) {
    try {
        let url = 'GetAlertsCount';
        const response = yield axios.get(url);
        console.log('[offers.js/saga] responsealertscount=', response.data);
        let totalCount = 0;
        for(let item of response.data)
        {
            totalCount += item.textMessageAlertCount;
            totalCount += item.priceOfferAlertCount;
        }
        yield all([put(actions.getAlertsCountSuccess(totalCount)),put(actions.getAlertsArraySuccess(response.data))]);
    } catch (error) {}
}

export function* getCartCountSaga(action) {
    try {
        let url = 'GetCartCount';
        console.log('[offers.js/saga] in getCartCountSaga ', axios.defaults.baseURL);
        const response = yield axios.get(url);
        yield all([put(actions.getCartCountSuccess(response.data)),put(actions.SeeIfThisCustomerSentHisPhotosToVerification(),
         put(actions.SeeIfThisCustomerIsSubscribedToATicket())   )]);
        
    } catch (error) {}
}

function countdown(secs) {
    return eventChannel(emitter => {
        const iv = setInterval(() => {
            secs -= 1;
            if (secs > 0) {
                emitter(secs);
            } else {
                // this causes the channel to close
                emitter(END);
            }
        }, 1000);
        // The subscriber must return an unsubscribe function
        return () => {
            clearInterval(iv);
        };
    });
}

function getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60).toString();
    var minutes = Math.floor((t / 1000 / 60) % 60).toString();
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24).toString();
    var days = Math.floor(t / (1000 * 60 * 60 * 24)).toString();
    return {
        total: t,
        days: days.length === 1 ? '0' + days : days,
        hours: hours.length === 1 ? '0' + hours : hours,
        minutes: minutes.length === 1 ? '0' + minutes : minutes,
        seconds: seconds.length === 1 ? '0' + seconds : seconds,
    };
}

export function* getOfferHistorySaga(action) {
    try {
        let url = 'GetOfferHistory/' + action.offerId + '?' + action.limit + '&offset=' + action.offset + '&user=' + action.name;
        const response = yield axios.get(url);
        const allItems = response.data.content;
        yield put(actions.getOfferHistorySuccess(allItems, response.data.count));
    } catch (error) {}
}

export function* counterOfferSaga(action) {
    try {
        let url = 'CounterOffer/' + action.offerId;
        let objectToSend = { price: action.offerPrice };
        const response = yield axios.post(url, objectToSend);
        console.log('[offers.js/saga] удачно получилось');
        yield put(actions.getOffer(action.offerId));
    } catch (error) {
        console.log('[offers.js/saga] неудачно получилось');
    }
}

export function* acceptOfferSaga(action) {
    try {
        let url = 'AcceptOffer/' + action.offerId;
        const response = yield axios.post(url);
        //console.log('[offers.js/saga] удачно получилось', action);

        let href = 'https://dev-public.mtwin.ru/CartAuthorized';
        
        if(action.env === 'PROD') href = 'https://www.mtwin.ru/CartAuthorized';
                
        window.open(href, '_blank');
        yield put(actions.getOffers(10, 0));
    } catch (error) {
        console.log('[offers.js/saga] неудачно получилось');
    }
}

export function* declineOfferSaga(action) {
    try {
        let url = 'DeclineOffer/' + action.offerId;
        const response = yield axios.post(url);
        console.log('[offers.js/saga] удачно получилось');
        yield put(actions.getOffer(action.offerId));
    } catch (error) {
        console.log('[offers.js/saga] неудачно получилось');
    }
}

export function* postTextMessageSaga(action) {
    try {
        let url = 'SendTextMessage/' + action.offerId;
        let objectToSend = { text: action.textMessage };
        const response = yield axios.post(url, objectToSend);
        console.log('[offers.js/saga] удачно получилось чёрная пакость L212 action.offerId=',action.offerId);
        yield put(actions.getChat(action.offerId));
    } catch (error) {
        console.log('[offers.js/saga] неудачно получилось');
    }
}

export function* setSeenBySaga(action) {
    try {
        let url = 'AddSeenByPersonToAChatItem/' + action.offerId;
        console.log('[offers.js/saga] sagawri_offerid=',action.offerId)
        console.log('[offers.js/saga] sagawri_messageids=',action.messageIds)
        let objectToSend = { messageIds: action.messageIds, messageType: action.messageType };
        console.log('[offers.js/saga] objectToSend=',objectToSend)
        const response = yield axios.post(url, objectToSend);
        console.log('[offers.js/saga] удачно получилось черная пакость L227 action.offerId',action.offerId);
        //yield put(actions.getChat(action.offerId));
        yield all([put(actions.getChat(action.offerId)),put(actions.getAlertsCount())]);
    } catch (error) {
        console.log('[offers.js/saga] неудачно получилось');
    }
}

