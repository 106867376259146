import {MsalAuthProvider, LoginType} from 'react-aad-msal';


const tenant = 'mtwin.onmicrosoft.com';
const signInPolicy = 'B2C_1_MtwinSignUpIn';
const applicationID = '67ba2684-c6d4-4193-b5fd-1616ff99bb5b';
//const reactRedirectUri = "https://dev-my.mtwin.ru";

let reactRedirectUri = null;

//alert(window.location.href);
if (window.location.href.indexOf("localhost") > -1)  reactRedirectUri = 'http://localhost:3000'; 
else if (window.location.href.indexOf("dev") > -1)  reactRedirectUri = 'https://dev-my.mtwin.ru';
else reactRedirectUri = 'https://my.mtwin.ru';
//alert(reactRedirectUri)

//const reactRedirectUri = process.env.REACT_APP_REDIRECT_URI;

const tenantSubdomain = tenant.split(".")[0];
const instance = `https://mtwin.b2clogin.com/mtwin.onmicrosoft.com/`;
//const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
const signInAuthority = `${instance}${signInPolicy}`;

const signInConfig = {
        auth: {
            authority: signInAuthority,
            clientId: '67ba2684-c6d4-4193-b5fd-1616ff99bb5b',
            redirectUri: reactRedirectUri,
            validateAuthority: false
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
            
        }
   
};

const authenticationParameters = { scopes: ['https://mtwin.onmicrosoft.com/CustomerAccountService/User.ReadWrite','https://graph.microsoft.com/offline_access','https://graph.microsoft.com/openid'] };

const options = {
    loginType: LoginType.Redirect
    //tokenRefreshUri: window.location.origin + "/auth.html"
};

export const signInAuthProvider = new MsalAuthProvider(
    signInConfig,
    authenticationParameters,
    options
);
