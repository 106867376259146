import * as actionTypes from './actionTypes';


export const getUserAddressList = (userId) => {
    return {
      type: actionTypes.GET_USER_ADDRESS_LIST,
      userId: userId
      
    };
  };

  export const sendSMS = (customerFio, customerPhone, confirmationNumber) => {
    return {
      type: actionTypes.SEND_SMS,
      customerFio: customerFio,
      customerPhone: customerPhone,
      confirmationNumber: confirmationNumber
      
    };
  };

  export const getUserAddressListSuccess = (payload) => {
    return {
      type: actionTypes.GET_USER_ADDRESS_LIST_SUCCESS,
      payload: payload
    };
  };

  export const sendSMSSuccess = () => {
    return {
      type: actionTypes.SEND_SMS_SUCCESS
    };
  };

  export const SeeIfThisCustomerSentHisPhotosToVerification = () => {
    return {
      type: actionTypes.GET_WHETHER_USER_VERIFICATION_INFO_EXISTS
      
    };
  };

  export const SeeIfThisCustomerIsSubscribedToATicket = () => {
    return {
      type: actionTypes.GET_WHETHER_USER_SUBSCRIBED_TO_TICKET
    };
  }

    export const SeeIfThisCustomerSentHisPhotosToVerificationSuccess = (payload) => {
    return {
      type: actionTypes.GET_WHETHER_USER_VERIFICATION_INFO_EXISTS_SUCCESS,
      payload: payload
    };
  };

  export const SeeIfThisCustomerIsSubscribedToATicketSuccess = () => {
    return {
      type: actionTypes.GET_WHETHER_USER_SUBSCRIBED_TO_TICKET_SUCCESS
    };
  }

  export const saveUserVerificationInfo = (img1,img2,img3,customerId,customerLogin,customerFirstname,customerLastname,userPhone) => {
    return {
      type: actionTypes.SAVE_USER_VERIFICATION_INFO,
      img1: img1,
      img2: img2,
      img3: img3,
      customerId: customerId,
      customerLogin: customerLogin,
      customerFirstname: customerFirstname,
      customerLastname: customerLastname,
      userPhone: userPhone
    };
  };

  export const saveUserVerificationInfoSuccess = () => {
    return {
      type: actionTypes.SAVE_USER_VERIFICATION_INFO_SUCCESS
    };
  };

  export const removeUserAddress = (userId, addressId) => {
    return {
      type: actionTypes.REMOVE_USER_ADDRESS,
      userId: userId,
      addressId: addressId
    };
  };

  export const setUserAddressAsDefault = (userId, addressId) => {
    return {
      type: actionTypes.SET_USER_ADDRESS_AS_DEFAULT,
      userId: userId,
      addressId: addressId
    };
  };

  export const saveUserAddress = (objectToSend) => {
    return {
      type: actionTypes.SAVE_USER_ADDRESS,
      payLoad: objectToSend
    };
  };

  
  export const confirmThatUserSubscribedToATicket = (payload) => {
    return {
      type: actionTypes.CONFIRM_THAT_USER_SUBSCRIBED_TO_TICKET,
      isSubscribed: payload.userAssignedToTicket,
      customerId: payload.customerId
    };
  };


 