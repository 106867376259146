import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import moment from 'moment';
import TablePagination from "@material-ui/core/TablePagination";
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {ruRU} from '@material-ui/core/locale';
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions';
import Input from '@material-ui/core/Input';
import AddressCard from './AddressCard/AddressCard';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import EditAddressDialog from './AddressCard/EditAddressDialog';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';




const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      rotten: {
        width: '100%',
        maxWidth: 400,
        backgroundColor: theme.palette.background.paper,
      },
      table: {
        minWidth: 650,
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      paper: {
        padding: theme.spacing(1),
        margin: "auto",
        maxWidth: 350
      },
      fab: {
        margin: 90,
        top: 70,
        right: 120,
        bottom: 120,
        left: 180,
        position: 'relative'
      },
      title: {
        fontSize: 14,
      },
      buttons: {
        marginTop: "30px"
      },
      submitButton: {
        marginLeft: "20px"
      }
        
    
  }));



const AddressList = (props) => {

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);

  const closeNewAddressDialog = () => { setNewAddressDialogOpen(false); }
  const handleClickOpen = () => {
    setNewAddressDialogOpen(true);

  };


  useEffect(() => { 
    props.getUserAddressList(props.userId); }, [])

    

    const classes = useStyles();
    return (


          <React.Fragment>
              <Container fixed >
              <Grid container justify="space-evenly" spacing={4}>
               
             
              <List component="nav" className={classes.rotten} aria-label="contacts">

{
              props.addressList.map((row, idx) => (
                <AddressCard 
                key={row.id}
                idx={idx} 
                eachAddress={row}
                // zip={row.zip} 
                // isDefault={row.default}
                // city={row.city} 
                // street={row.street} 
                // corp={row.corp} 
                // home={row.home} 
                // flat={row.flat} 
                // description={row.description} 
                // id={row.id}
                // addressId={row.id}
                addressListCount={props.addressList.length}
                >
                  

                </AddressCard>
   
              ))
}

              </List>  
                  
                 

             

                  {/* <Grid item sm={12} md={6}  >
                  <Card className={classes.root} variant="outlined">
                      <CardContent>
                      <Grid container justify="center" alignItems="center">
                        <Button variant="outlined" color="primary" size="large" onClick={() => {setEditAddressDialogOpen(true)}}>Добавить новый адрес доставки...</Button>
                      </Grid>
                  </CardContent>
                  </Card>
                  </Grid> */}

                  
                            </Grid>
                            <Fab onClick={() => setNewAddressDialogOpen(true)} color="primary" aria-label="Add" style={{margin: 0, top: 'auto', right: 20, bottom: 30, left: 'auto', position: 'fixed'}}><AddIcon /></Fab>
              </Container>

              {newAddressDialogOpen ? (<EditAddressDialog open={newAddressDialogOpen} modus="new" dialogClose={closeNewAddressDialog}></EditAddressDialog>) : null}
              
          </React.Fragment>
  

    )
}  

const mapStateToProps = state => {
    return {
        accessToken: state.auth.accessToken,
        addressList: state.profile.addressList,
        userId: state.auth.userId
    }
};

const mapDispatchToProps = dispatch => {
  return {
    getUserAddressList: (userId ) => dispatch(actions.getUserAddressList(userId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressList));