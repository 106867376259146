import React from 'react';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
        flexGrow: 1,
      },
  }));

const settings = (props) => {
    const classes = useStyles;
    return (
        <React.Fragment>
            <Typography variant="h6" className={classes.title}>Настройки</Typography>
            <Hidden smUp implementation="css">
                <Typography>smUp</Typography>
            </Hidden>
            <Hidden xsDown implementation="css"> 
                <Typography>xsDown</Typography>
            </Hidden>

        </React.Fragment>
    )
}

export default settings;