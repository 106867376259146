import * as React from "react"

function CancelSvg(props) {
  return (
    <svg 
    width={props.width}
    height={props.height}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 512 512" {...props}>
      <path
        d="M255.832 32.021c123.697.096 223.907 100.45 223.811 224.147s-100.45 223.907-224.147 223.811C131.863 479.883 31.685 379.633 31.685 256c.184-123.689 100.458-223.883 224.147-223.979m0-32.021C114.443.096-.096 114.779 0 256.168S114.779 512.096 256.168 512C397.485 511.904 512 397.317 512 256 511.952 114.571 397.261-.048 255.832 0z"
        fill="#ccc"
      />
      <g fill="#e21b1b">
        <path d="M135 174.776l39.617-39.617 202.13 202.129-39.618 39.617z" />
        <path d="M134.995 337.232l202.129-202.13 39.617 39.618-202.13 202.13z" />
      </g>
    </svg>
  )
}

export default CancelSvg