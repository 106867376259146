import * as React from "react"

function UserAddressSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 241.644 241.644"
      width="1.7em"
      height="1.7em"
      {...props}
    >
      <path d="M197.808 15.912h-66.064V0h-20.696v15.912H99.296c-15.028 0-18.18 6.344-18.18 11.668v15.632c0 5.324 3.152 11.668 18.18 11.668h11.752v21.256H43.996l-20.6 19.972 20.628 19h67.028v110.164H76.836c-5.468 0-9.752 6.312-9.752 14.372v2h111.128v-2c0-8.06-4.284-14.372-9.752-14.372h-36.712v-110.16h11.42c15.116 0 18.288-6.344 18.288-11.668V87.812c0-5.324-3.172-11.668-18.288-11.668h-11.42V54.888h66.088v-.004l20.412-19.972-20.44-19zM115.048 4h12.696v2.476h-12.696V4zm0 3.608h12.696v8.304h-12.696V7.608zm53.408 221.668v-.004c2.416 0 5.072 3.348 5.64 8.372h-102.9c.22-1.948.764-3.636 1.476-4.984h66.284v-1.132H73.364c1.028-1.432 2.28-2.252 3.472-2.252h91.62zm-53.408-4V115.112h12.696v110.164h-12.696zm28.12-145.132v-.004c14.288 0 14.288 5.772 14.288 7.668v15.632c0 1.896 0 7.668-14.288 7.668H45.584l-4.568-4.208h25.732v-1.128H39.784l-6.528-6.008h11.12v-1.132H32.032l-2.816-2.592 3.524-3.416h31.728v-1.132h-30.56l6.2-6.008h8.776v-1.132h-7.608l4.34-4.208h97.552zm-28.12-4.004V54.884h12.696V76.14h-12.696zm93.072-36.92l-6.144 6.008H190.94v1.132h9.88l-4.62 4.52H99.3v.004c-14.18 0-14.18-5.772-14.18-7.668V27.584c0-1.896 0-7.668 14.18-7.668h96.932l4.188 3.892h-28.064v1.132h29.284l6.464 6.008h-12.476v1.132h13.692l3.128 2.908-3.168 3.1h-34.552v1.132h33.392z" />
      <path d="M121.396 31.916a3.485 3.485 0 00-3.48 3.48c0 1.92 1.564 3.48 3.48 3.48s3.48-1.56 3.48-3.48-1.56-3.48-3.48-3.48zm0 5.828a2.352 2.352 0 01-2.348-2.348c0-1.296 1.056-2.348 2.348-2.348s2.348 1.052 2.348 2.348a2.349 2.349 0 01-2.348 2.348zM121.396 99.104c1.916 0 3.48-1.56 3.48-3.48s-1.564-3.48-3.48-3.48-3.48 1.56-3.48 3.48 1.564 3.48 3.48 3.48zm0-5.828a2.352 2.352 0 012.348 2.348c0 1.296-1.056 2.348-2.348 2.348s-2.348-1.052-2.348-2.348a2.352 2.352 0 012.348-2.348zM177.096 30.944h12.408v1.132h-12.408zM169.976 45.212H184.8v1.132h-14.824zM50.252 98.612h11.924v1.132H50.252zM54.784 84.344h14.248v1.132H54.784zM144.056 231.532h18.152v1.132h-18.152z" />
    </svg>
  )
}

export default UserAddressSvg
