export const AAD_INITIALIZING = 'AAD_INITIALIZING';
export const AAD_INITIALIZED = 'AAD_INITIALIZED';
export const AAD_AUTHENTICATED_STATE_CHANGED = 'AAD_AUTHENTICATED_STATE_CHANGED';
export const AAD_ACQUIRED_ID_TOKEN_SUCCESS = 'AAD_ACQUIRED_ID_TOKEN_SUCCESS';
export const AAD_ACQUIRED_ID_TOKEN_ERROR = 'AAD_ACQUIRED_ID_TOKEN_ERROR';
export const AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS = 'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS';
export const AAD_ACQUIRED_ACCESS_TOKEN_ERROR = 'AAD_ACQUIRED_ACCESS_TOKEN_ERROR';
export const AAD_LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';
export const AAD_LOGIN_FAILED = 'AAD_LOGIN_FAILED';
export const AAD_LOGIN_ERROR = 'AAD_LOGIN_ERROR';
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS';
export const GET_GRAPH_ACCESS_TOKEN = 'GET_GRAPH_ACCESS_TOKEN';
export const GET_GRAPH_ACCESS_TOKEN_SUCCESS = 'GET_GRAPH_ACCESS_TOKEN_SUCCESS';
export const GET_USER_CREDENTIALS = 'GET_USER_CREDENTIALS';
export const GET_USER_CREDENTIALS_SUCCESS = 'GET_USER_CREDENTIALS_SUCCESS';
export const UPDATE_USER_CREDENTIALS = 'UPDATE_USER_CREDENTIALS';

export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_COUNT_BY_TYPES_SUCCESS = 'GET_OFFERS_COUNT_BY_TYPES_SUCCESS';
export const GET_OFFERS_COUNT = 'GET_OFFERS_COUNT';
export const GET_ALERTS_COUNT = 'GET_ALERTS_COUNT';
export const GET_OFFERS_COUNT_SUCCESS = 'GET_OFFERS_COUNT_SUCCESS';
export const GET_ALERTS_COUNT_SUCCESS = 'GET_ALERTS_COUNT_SUCCESS';
export const GET_CART_COUNT = 'GET_CART_COUNT';
export const GET_CART_COUNT_SUCCESS = 'GET_CART_COUNT_SUCCESS';
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS';
export const GET_OFFER = 'GET_OFFER';
export const CLEAR_OFFER = 'CLEAR_OFFER';
export const GET_CHAT = 'GET_CHAT';
export const CLEAR_CHAT = 'GET_CHAT';
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS';
export const OFFER_TIMER_COUNTDOWN = 'OFFER_TIMER_COUNTDOWN';
export const GET_OFFER_HISTORY = 'GET_OFFER_HISTORY';
export const GET_OFFER_HISTORY_SUCCESS = 'GET_OFFER_HISTORY_SUCCESS';
export const COUNTER_OFFER = 'COUNTER_OFFER';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';
export const DECLINE_OFFER = 'DECLINE_OFFER';
export const POST_TEXT_MESSAGE = 'POST_TEXT_MESSAGE';
export const SET_SEEN_BY = 'SET_SEEN_BY';
export const GET_ALERTS_ARRAY_SUCCESS = 'GET_ALERTS_ARRAY_SUCCESS';

export const GET_USER_ADDRESS_LIST = 'GET_USER_ADDRESS_LIST';
export const GET_USER_ADDRESS_LIST_SUCCESS = 'GET_USER_ADDRESS_LIST_SUCCESS';
export const REMOVE_USER_ADDRESS = 'REMOVE_USER_ADDRESS';
export const SET_USER_ADDRESS_AS_DEFAULT = 'SET_USER_ADDRESS_AS_DEFAULT';
export const SAVE_USER_ADDRESS = 'SAVE_USER_ADDRESS';
export const SAVE_USER_ADDRESS_SUCCESS = 'SAVE_USER_ADDRESS_SUCCESS';
export const SAVE_USER_VERIFICATION_INFO = 'SAVE_USER_VERIFICATION_INFO';
export const SAVE_USER_VERIFICATION_INFO_SUCCESS = 'SAVE_USER_VERIFICATION_INFO_SUCCESS';
export const GET_WHETHER_USER_VERIFICATION_INFO_EXISTS = 'GET_WHETHER_USER_VERIFICATION_INFO_EXISTS';
export const GET_WHETHER_USER_VERIFICATION_INFO_EXISTS_SUCCESS = 'GET_WHETHER_USER_VERIFICATION_INFO_EXISTS_SUCCESS';
export const GET_WHETHER_USER_SUBSCRIBED_TO_TICKET = 'GET_WHETHER_USER_SUBSCRIBED_TO_TICKET';
export const GET_WHETHER_USER_SUBSCRIBED_TO_TICKET_SUCCESS = 'GET_WHETHER_USER_SUBSCRIBED_TO_TICKET_SUCCESS';
export const SEND_SMS = 'SEND_SMS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const CONFIRM_THAT_USER_SUBSCRIBED_TO_TICKET = 'CONFIRM_THAT_USER_SUBSCRIBED_TO_TICKET';

export const DO_TICKET_CHECK = 'DO_TICKET_CHECK';
export const DO_TICKET_CHECK_SUCCESS = 'DO_TICKET_CHECK_SUCCESS';
export const RESET_CONFIRMATION_RESULT = 'RESET_CONFIRMATION_RESULT';
export const CONFIRM_USER_AS_VALID_TICKET_CUSTOMER = 'CONFIRM_USER_AS_VALID_TICKET_CUSTOMER';
export const CLEAR_CUSTOMER_TICKET_SUBSCRIPTION = 'CLEAR_CUSTOMER_TICKET_SUBSCRIPTION';
export const GET_TICKET_LIST = 'GET_TICKET_LIST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';
export const GET_TICKET = 'GET_TICKET';
export const MEMORIZE_TICKET_LIST = 'MEMORIZE_TICKET_LIST';
export const CLEAR_SINGLE_TICKET = 'CLEAR_SINGLE_TICKET';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';


export const GET_USER_CLAIMS = 'GET_USER_CLAIMS';