import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ButtonBase from "@material-ui/core/ButtonBase";
import WithNumberFormat from '../../Hoc/WithNumberFormat';
import { connect } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as actions from '../../../store/actions';
import moment from 'moment';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ThumbsUpSvg from '../../UI/Icons/ThumbsUpSvg';
import MegaphoneFilledSvg from '../../UI/Icons/MegaphoneFilledSvg';
import HourglassColorSvg from '../../UI/Icons/HourglassColor';
import HourglassColorStrikedoutSvg from '../../UI/Icons/HourglassColorStrikedout';
import { UrlUtils } from 'msal';


const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
      },
      paper: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: 550
      },
      image: {
        width: 128,
        height: 128
      },
      img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%"
      },
      orangeText: {
        color: "orange"
      },
      blueText: {
        color: "blue"
      },
      blueTextBordered: {
        color: "blue",
        fontSize: '1.1Em'
        //border: "1px solid"
      }
    
  }));

  const fontWeightMedium = 600;
const themePoppins = createMuiTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', fontWeightMedium
      },
  })

  const getCorrectTitleStyle = (offerType) => {
    switch(offerType)
    {
      case 4 : return 'textPrimary';
      case 3 : return 'primary';
      case 2 : return 'secondary';
      case 1 : return 'secondary';
      default: return 'initial'
    }
  }

  const getRightRightIcon = (offerType, isExpired) => {
    switch(offerType)
    {
      case 3: return <ThumbsUpSvg fill='#1ABC9C' width='1.8em' height='1.8em'/>
      case 2: if(isExpired) return  (<HourglassColorStrikedoutSvg fill='#757575' width='1.8em' height='1.8em' />); else return (<HourglassColorSvg fill='#757575' width='1.8em' height='1.8em' />)
      case 1: return  <MegaphoneFilledSvg fill='#757575' width='1.8em' height='1.8em' />
    }
  }


  
  const getRightOfferWord = (offerType,offerDate) => {
    let toReturn = undefined;
    switch (offerType) {
        case 3:
            toReturn = 'Принято:  '; break;
        case 2:
            toReturn = 'ВСТРЕЧНОЕ:  '; break;
        case 1:
            toReturn = 'Предложено:  '; break;
        default:
            toReturn = ' ';
    }
    return toReturn + moment(offerDate).format("DD.MM.YYYY HH:mm");
    //return toReturn;
};

  const ItemForMobile = (props) =>
  {
    
    const classes = useStyles();


    return (
      <React.Fragment>
     
        {/* <div className={classes.root} style={{ backgroundImage: "stamp.png", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}> */}
        <div className={classes.root}>
        <Link href="#" to={props.isExpired ? null : '/itemcard/' + props.offerId} style={{textDecoration: 'none'}}> 
          {/* {
          !props.isExpired ? <Link href="#" to={'/itemcard/' + props.offerId} style={{textDecoration: 'none'}}> : null 
          } */}
        
          <Paper className={classes.paper} elevation={5} style={{
            marginBottom: "5px", 
            backgroundImage: props.isExpired? "url(stamp-grey.PNG)" : "none", 
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'}}>
            <Grid container spacing={2} >
              <Grid item>
              
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    alt="фотография товара"
                     src={"https://www.mtwin.ru/api/image/id/" + props.pic + "/300/450"}
                    
                  />
                </ButtonBase>
                
              </Grid>
            <Grid item xs={12} sm container spacing={1}  >
              <Grid item xs container direction="column" spacing={1} >
                <Grid item xs >
                  <Typography gutterBottom variant="h5">
                      {props.lotName}
                  </Typography>
                  
                  <ThemeProvider theme={themePoppins}>
                  <Typography variant="body2" color="textSecondary">
                    КОД: {props.internalNumber}
                  </Typography>
                  </ThemeProvider>
                  <ThemeProvider theme={themePoppins}>
                  <Typography variant="body2" display='inline' gutterBottom style={{marginRight: 5, fontWeight: '600'}}>
                    {getRightOfferWord(props.offerType, props.offerDate )} 
                  </Typography>
                  </ThemeProvider>
                  {/* <Typography variant="body2" display='inline' color="secondary" style={{marginRight: 5, fontSize: '1.1Em'}}>{moment(props.offerDate).format("DD.MM.YYYY HH:mm")}</Typography> */}
                  {
                props.offerType == 2 && moment(props.expiredDate).isAfter(moment()) ? (
                  <React.Fragment>
                <Typography variant="body2" gutterBottom style={{marginRight: 5, fontSize: '1.1Em'}}>
                Истекает:
              </Typography>
              {/* <Typography variant="caption" display='inline' className={classes.blueTextBordered}>{moment(props.expiredDate).format("DD.MM.YYYY HH:mm")}</Typography> */}
              <Typography variant="body2" display='inline' color="secondary" style={{marginRight: 5, fontSize: '1.1Em'}}>{moment(props.expiredDate).format("DD.MM.YYYY HH:mm")}</Typography>
              </React.Fragment>
              ) : ""
                
                }
                </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
            {
              props.showPriceOfferAlert ?  <NotificationsActiveIcon  style={{color: '#ff9100'}}/> : null
            }
            {
              props.showTextAlert ?  <MailIcon  style={{color: 'red'}}/> : null
            }
            {
              getRightRightIcon(props.offerType, props.isExpired)
            }
          </Grid>
          <Grid container justify="flex-end" >

          <Grid item>
                

                <Typography variant="h6" color={getCorrectTitleStyle(props.offerType)} style={{fontWeight: 600}}><WithNumberFormat value={props.offerPrice} digits={2} />&nbsp;₽</Typography>
                {/* {
                props.offerType == 2 && moment(props.expiredDate).isAfter(moment()) ? (<Typography variant="body2" className={classes.blueTextBordered}>{moment(props.expiredDate).format("DD.MM.YYYY HH:mm")}</Typography>) : ""
                
                } */}
            </Grid>

          </Grid>
        </Grid>
      </Paper>
      </Link>
   
      
    </div>
    
        </React.Fragment>
    )
  }

  const mapStateToProps = state => {
    return {
        accountInfo: state.auth.idToken
    }
};

  const mapDispatchToProps = dispatch => {
    return {
        getOfferItems: (token,limit,offset) => dispatch(actions.getOffers(token,limit,offset))
    };
};

  export default connect(mapStateToProps, mapDispatchToProps)(ItemForMobile);
  