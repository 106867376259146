import * as React from "react"

const HourglassColorStrikedoutSvg = (props) => (
    <svg 
    viewBox="0 0 512 512"
    width={props.width} 
    height={props.height} 
    xmlns="http://www.w3.org/2000/svg" 
    {...props}>
    <title>{"Layer 1"}</title>
    <path
      fill="#E4EAF6"
      d="M132.414 44.138v67.851a70.626 70.626 0 0 0 20.684 49.937l71.249 71.249a17.654 17.654 0 0 1 5.171 12.484v20.684c0 4.682-1.86 9.173-5.171 12.484l-71.249 71.249a70.615 70.615 0 0 0-20.684 49.935v67.851h247.172v-67.851a70.626 70.626 0 0 0-20.684-49.937l-71.249-71.249a17.654 17.654 0 0 1-5.171-12.484v-20.684c0-4.682 1.86-9.173 5.171-12.484l71.249-71.249a70.617 70.617 0 0 0 20.684-49.935V44.138H132.414z"
    />
    <path
      fill="#D7DEED"
      d="M220.69 115.456v-62.49h-88.276v59.023a70.627 70.627 0 0 0 20.684 49.937l71.247 71.247a17.657 17.657 0 0 1 5.172 12.484v20.684c0 4.682-1.86 9.173-5.171 12.484l-71.247 71.247a70.622 70.622 0 0 0-20.685 49.938v59.023h88.276v-62.49c0-17.078 2.753-34.046 8.154-50.248l14.705-44.114a70.632 70.632 0 0 0 3.624-22.333v-47.7a70.632 70.632 0 0 0-3.624-22.333l-14.705-44.114a158.861 158.861 0 0 1-8.154-50.245z"
    />
    <path
      fill="#FFD782"
      d="m340.177 368.802-28.182-28.182a17.654 17.654 0 0 0-12.484-5.171h-17.027c-9.751 0-17.655-7.904-17.655-17.655v-84.623a35.313 35.313 0 0 1 10.343-24.969l43.066-43.063a8.823 8.823 0 0 0 1.913-9.621 8.836 8.836 0 0 0-8.156-5.449h-111.99a8.828 8.828 0 0 0-6.243 15.07l43.066 43.063a35.309 35.309 0 0 1 10.343 24.969v84.622c0 9.751-7.904 17.655-17.655 17.655h-17.028a17.652 17.652 0 0 0-12.483 5.17l-28.179 28.174a44.15 44.15 0 0 0-12.929 31.217v32.543a8.829 8.829 0 0 0 8.828 8.828h176.552a8.829 8.829 0 0 0 8.828-8.828V400.01a44.143 44.143 0 0 0-12.928-31.208z"
    />
    <g fill="#FFC36E">
      <path d="M191.849 155.518a8.828 8.828 0 0 0 1.913 9.621l43.066 43.062a35.31 35.31 0 0 1 10.344 24.969v-1.02a70.632 70.632 0 0 0-3.624-22.333l-14.705-44.115c-1.711-5.135-3.059-10.367-4.235-15.634h-24.604a8.84 8.84 0 0 0-8.155 5.45zM220.69 396.544c0-17.078 2.753-34.046 8.154-50.248l3.719-11.155c-.993.173-2.002.307-3.044.307H212.49a17.647 17.647 0 0 0-12.483 5.171l-28.179 28.174a44.146 44.146 0 0 0-12.931 31.217v32.542a8.829 8.829 0 0 0 8.828 8.828h52.966l-.001-44.836z" />
    </g>
    <path
      fill="#5B5D6E"
      d="M379.586 52.966H132.414a8.829 8.829 0 0 1-8.828-8.828V26.483h264.828v17.655a8.829 8.829 0 0 1-8.828 8.828z"
    />
    <path
      fill="#464655"
      d="M220.69 44.138V26.483h-97.103v17.655a8.829 8.829 0 0 0 8.828 8.828h97.103a8.828 8.828 0 0 1-8.828-8.828z"
    />
    <path
      fill="#959CB3"
      d="M388.414 35.31H123.586a8.829 8.829 0 0 1-8.828-8.828V8.828A8.829 8.829 0 0 1 123.586 0h264.828a8.829 8.829 0 0 1 8.828 8.828v17.655a8.829 8.829 0 0 1-8.828 8.827z"
    />
    <path
      fill="#707487"
      d="M211.862 26.483V8.828A8.829 8.829 0 0 1 220.69 0h-97.103a8.829 8.829 0 0 0-8.828 8.828v17.655a8.829 8.829 0 0 0 8.828 8.828h97.103a8.83 8.83 0 0 1-8.828-8.828z"
    />
    <path
      fill="#5B5D6E"
      d="M379.586 459.034H132.414a8.829 8.829 0 0 0-8.828 8.828v17.655h264.828v-17.655a8.829 8.829 0 0 0-8.828-8.828z"
    />
    <path
      fill="#464655"
      d="M220.69 467.862v17.655h-97.103v-17.655a8.829 8.829 0 0 1 8.828-8.828h97.103a8.828 8.828 0 0 0-8.828 8.828z"
    />
    <path
      fill="#959CB3"
      d="M388.414 476.69H123.586a8.829 8.829 0 0 0-8.828 8.828v17.655a8.829 8.829 0 0 0 8.828 8.828h264.828a8.829 8.829 0 0 0 8.828-8.828v-17.655a8.829 8.829 0 0 0-8.828-8.828z"
    />
    <path
      fill="#707487"
      d="M211.862 485.517v17.655A8.829 8.829 0 0 0 220.69 512h-97.103a8.829 8.829 0 0 1-8.828-8.828v-17.655a8.829 8.829 0 0 1 8.828-8.828h97.103a8.83 8.83 0 0 0-8.828 8.828z"
    />
    <path strokeWidth={25} stroke="red" fill="none" d="m473 33.6-431 440" />
  </svg>
)

export default HourglassColorStrikedoutSvg
