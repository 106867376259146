import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CreditCardSvg from "../UI/Icons/CreditCardSvg";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as actions from '../../store/actions';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import WithNumberFormat from '../Hoc/WithNumberFormat';
import moment from "moment";
import { useTheme } from '@material-ui/core/styles';
import BackwardSvg from '../UI/Icons/BackwardSvg';
import { useHistory } from "react-router-dom";
import { Tooltip } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: 10,
    },
    paper: {
        padding: theme.spacing(4),
        margin: 'auto',
        
    },
    image: {
        width: 200,
        height: 200,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    orangeText: {
        color: 'orange',
    },
    fab: {
        margin: 90,
        top: 70,
        right: 120,
        bottom: 120,
        left: 180,
        position: 'relative',
    },
    blueText: {
        color: 'blue',
    },
    blueTextBordered: {
        color: 'blue',
        border: '1px solid',
    },
    redColor: {
        backgroundColor: 'red',
        padding: '10px 10px',
    },
    yellowColor: {
        backgroundColor: 'yellow',
    },
    greenColor: {
        backgroundColor: 'green',
    },
    blueColor: {
        backgroundColor: 'blue',
    },
    orangeColor: offerType => {
        switch (offerType) {
            case 2:
                return {
                    backgroundColor: 'orange',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 1:
                return {
                    backgroundColor: '#2196f3',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 3:
                return {
                    backgroundColor: '#1ABC9C',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
            case 4:
                return {
                    backgroundColor: 'grey',
                    textAlign: 'right',
                    padding: '10px 10px',
                    color: 'white',
                    fontSize: '1.1Em',
                };
        }
    },
    infoColor: { backgroundColor: theme.palette.info.main, color: 'white' },
    successColor: { backgroundColor: theme.palette.success.main, color: 'white' },
}));

const fontWeightMedium = 700;
const themePoppins = createMuiTheme({
    typography: {
        fontFamily: '"Poppins", sans-serif', fontWeightMedium
        
      },
      palette: {
    
        primary: {
          main: '#1ABC9C'  // зелёный от MTWIN
        },
          secondary: {
              //main: '#2196f3',  //индиго от MUI
              main: '#757575', //тёмно-серый от Бори
              
          },
          error: {
              main: '#ff9100',  //оранжевый цвет
          },
      },
  })

  const TicketCard = props => {
    const { match } = props;
    const { params } = match;
    const { ticketId } = params;
    const theme = useTheme();
    const classes = useStyles();
    const [ticket, setTicket] = useState({});
    const [ticketItems, setTicketItems] = useState([]);

    let history = useHistory();

    useEffect(() => {

        if(!props.singleTicket) props.getTicket(ticketId)
        if(props.singleTicket)
        {
            console.log('props.singleTicket',props.singleTicket)
            setTicket(props.singleTicket);
            setTicketItems(props.singleTicket.ticketItems);
        }
        
    }, [props.singleTicket]);

    const doTicketItems = ( )=> {
        console.log('pipcher=',ticketItems)
        ticketItems.map((item,index) => {
            return (
                <React.Fragment>
                    <div>Groholm</div>
                </React.Fragment>
            )
        })

        // return (
        //              <React.Fragment>
        //                  <div>Groholm</div>
        //              </React.Fragment>
        //          )
        
    }

    return (
        <React.Fragment>
        {/* <CssBaseline /> */}
        <Paper className={classes.paper} elevation={7} style={{marginBottom: "20px"}}>
        <Tooltip title="Вернуться на предыдущую страницу">
        <IconButton color='inherit' onClick={history.goBack}> <BackwardSvg width="1.7em" height="1.7em" /> </IconButton>
        </Tooltip>
            <Container>
                <Grid container direaction="column" justify="center">

                    <Grid container>
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >{ticket.ticketNumber || ''}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{ticket.stateReadable || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={10}>
                    <Typography variant="subtitle1" >{ticket.ticketName || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>    
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >Дата займа</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{moment(ticket.dateOfIssue).format("DD.MM.YYYY") || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >Дата окончания договора</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{moment(ticket.termInDate).format("DD.MM.YYYY") || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >Окончание льготного периода</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{moment(ticket.endOfGracePeriodDate).format("DD.MM.YYYY") || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >Ставка (% в день)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{ticket.interestRate || ''}</Typography>
                    </Grid>
                    </Grid>

                    <Grid container>
                    <Grid item xs={7} >
                    <Typography variant="subtitle1" >Дней пользования займом</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" >{ticket.daysInUse || ''}</Typography>
                    </Grid>
                    </Grid>


                    <Grid container>
                    <Grid item xs={7}>
                    <Typography variant="subtitle1" >Начислено за пользование займом (руб.)</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography variant="subtitle1" ><WithNumberFormat value={ticket.interestsToThisDay || ''} digits={2} /></Typography>
                    </Grid> 
                    </Grid>

                    <Grid container style={{marginBottom: '20px', marginTop: '20px'}}>
                        <Grid item xs={8}>
                        <Divider />
                            </Grid>
                    </Grid>

                    
                    
                    
                    {
                        ticketItems.map((item,index) => {
                            return (
                                <React.Fragment>
                                    <Grid container spacing={3}>
                                    <Grid item xs={7}>
                                     <Typography variant="subtitle2" key={index}>{item.itemName}</Typography>
                                     </Grid>
                                     <Grid item xs={3}>
                                     <span style={{fontSize: '0.7em', fontStyle: 'italic'}}>займ (руб.)</span><br/>{item.loan}<br/>
                                     <span style={{fontSize: '0.7em', fontStyle: 'italic'}}>оценка (руб.)</span><br/>{item.estimate}
                                     {/* <Typography variant="subtitle2" key={index}>

                                         {item.loan}
                                         </Typography> */}
                                    </Grid>
                                     </Grid>
                                </React.Fragment>
                            )
                        })
                    }
        
                    
                 



                </Grid>
            </Container>
            </Paper>
        </React.Fragment>
    )
  }

  const mapStateToProps = state => {
    return {
         singleTicket: state.ticket.singleTicket,
        // timer: state.offers.timer,
        // alertsArray: state.offers.alertsArray,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTicket: ticketId => dispatch(actions.getTicket(ticketId)),
        
        // acceptOffer: offerId => dispatch(actions.acceptOffer(offerId)),
        // setSeenBy: (offerId, messageIds, messageType) => dispatch(actions.setSeenBy(offerId, messageIds, messageType)),
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketCard));