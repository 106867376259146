import React, {useEffect} from 'react';
import './App.css';
import {connect} from 'react-redux';
import Hidden from '@material-ui/core/Hidden'
import {Route, Switch, withRouter} from 'react-router-dom';
import Home from './components/Home/Home';
import Offers from './components/Bargain/Offers';
import Settings from './components/Settings/Settings';
import ItemCard from './components/Bargain/ItemCard/ItemCard';
import TicketCard from './components/Ticket/TicketCard';
import MyDrawer from './components/Drawer/Drawer';
import {Drawer as MUIDrawer} from '@material-ui/core';
import AppHeader from './components/Appheader/Appheader';
import { makeStyles, useTheme, ThemeProvider, theme, createMuiTheme } from '@material-ui/core/styles';
import * as actions from './store/actions';
import Profile from './components/Profile/Profile';
import CssBaseline from '@material-ui/core/CssBaseline';
import AddressList from './components/Profile/AddressList/AddressList';
import Lato from 'typeface-lato';
import { colors } from '@material-ui/core'
import Verification from './components/Profile/Verification/Verification';
import TicketList from './components/Ticket/TicketList';


//const useStyles = makeStyles({});
const drawerWidth = 240;

const changedTheme = createMuiTheme({
  palette: {
    
    primary: {
      main: '#1ABC9C'  // зелёный от MTWIN
    },
      secondary: {
          //main: '#2196f3',  //индиго от MUI
          main: '#757575', //тёмно-серый от Бори
          
      },
      error: {
          main: '#ff9100',  //оранжевый цвет
      },
  },
  typography: {
    fontFamily: '"Lato", sans-serif'
  },
  overrides: {
    MuiCssBaseline: {'@global': {'@font-face': [Lato]}},
    // MuiListItem: { root: {"&$selected": {color: colors.blue[500], backgroundColor: colors.lightBlue[100]}}},
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

//const container = window !== undefined ? () => window().document.body : undefined;
//const container = window().document.body;


function App(props) {

  const { window } = props;
  const { history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    getAccessToken(); }, [])

  const getAccessToken = () => {
    props.getAccessToken();
  }

  const getGraphAccessToken = () => {
    
    props.getGraphAccessToken();
  }


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const container = window !== undefined ? () => window().document.body : undefined;
  
  return (
  <React.Fragment>
    <ThemeProvider theme={changedTheme}>
      <div className={classes.root}>
      <CssBaseline />

      <AppHeader
        toggleDrawer={() => handleDrawerToggle()}
      />




      <nav className={classes.drawer} aria-label="mailbox folders">
        
          <Hidden smUp implementation="css">
          <MUIDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MyDrawer />
          </MUIDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <MUIDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open
          >
     
            {/* {drawer} */}
            <MyDrawer />
          </MUIDrawer>
        </Hidden> 
      </nav>

     
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
        <Route exact from="/" render={props => <Home {...props} />} />
        <Route exact from="/settings" render={props => <Settings {...props} />} />
        <Route exact from="/offers" render={props => <Offers {...props} />} />
        <Route exact from="/itemcard/:offerId" component={ItemCard} />
        <Route exact from="/ticketcard/:ticketId" component={TicketCard} />
        <Route exact from="/profile" component={Profile} />
        <Route exact from="/address" component={AddressList} />
        <Route exact from="/verify" component={Verification} />
        <Route exact from="/ticketlist" component={TicketList} />
        {/* <Route exact from="/offerhistory/:offerId" component={OfferHistory} /> */}
      </Switch>
      </main> 
 
      </div>
      </ThemeProvider>
  </React.Fragment>
  );
    

}
  
const mapDispatchToProps = dispatch => {
  return {
  
       getAccessToken: () => dispatch(actions.getAccessToken()),
       getGraphAccessToken: () => dispatch(actions.getGraphAccessToken())
  };
};
  
export default connect(null, mapDispatchToProps)(withRouter(App));
