import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import UserSubscriptionConfirmation from "./UserSubscriptionConfirmation";
import * as actions from '../../store/actions';
import { Button } from "@material-ui/core";
import moment from "moment";
import AppBar from '@material-ui/core/AppBar';
import Paper from "@material-ui/core/Paper";
import Toolbar from '@material-ui/core/Toolbar';
import WithNumberFormat from '../Hoc/WithNumberFormat';
import Typography from '@material-ui/core/Typography';
import TicketItem from './TicketItem';
import { Alert } from "react-st-modal";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    input: {
      display: "qone",
    },

    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: 224,
      maxHeight: 160,
    },
    imgPreview: {
      //margin: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      //display: "block",
      maxWidth: 180,
      maxHeight: 128,
    },
    cancelButton: {
      marginBottom: theme.spacing(5),
    },
    actionsContainer: {
      marginBottom: theme.spacing(4),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));

  const ListForCustomerConfirmed = (props) => {
    const classes = useStyles();

    const [totalInterests, setTotalInterests] = useState(70);

    useEffect(() => {
    props.clearSingleTicket();
     if(props.ticketList) 
     {
       let total = 0;
       for(let oneItem of props.ticketList)
       {
         if(oneItem.thisRowIsSelected) total += oneItem.interestsToThisDay;
       }
       console.log('total=',typeof total)
       setTotalInterests(total)
     }
     
  }, [props.ticketList, props.ticketListMemorized])

const clearCustomerSubscription = () => { props.clearCustomerTicketSubscription(props.objectId);}
const handleChange = (checked,ticketId) => {
  // let total = 0;
  // for(let oneItem of props.ticketList)
  // {
  //   if(oneItem.thisRowIsSelected) total += oneItem.interestsToThisDay;
  // }
  
  let idx = props.ticketList.findIndex(chr => chr.ticketId == ticketId);
  props.ticketList[idx].thisRowIsSelected = checked;
  let total = 0;
  for(let oneItem of props.ticketList)
  {
    if(oneItem.thisRowIsSelected) total += oneItem.interestsToThisDay;
  }
  console.log('total=',total)
  setTotalInterests(total)
  //props.memorizeTicketList(props.ticketList);
}

const handleClickOnLink = () => {
  props.memorizeTicketList(props.ticketList);
}

      return (
        <React.Fragment>
          
          {/* <Alert severity="warning">This is a warning alert — check it out!</Alert> */}
          <Grid container style={{ padding: '10px 10px' }} justify='center' spacing={1}>
          <Grid item xs={10} sm={12} md={12} lg={7} xl={6}>
          <Paper className={classes.paper} elevation={5} style={{marginBottom: "20px", backgroundColor: '#FFF4E5'}}>
          <Typography variant="subtitle1" style={{fontWeight: 500}} >К сожалению, в настоящее время оплата из личного кабинета недоступна, так как соответствующий сервис находится в разработке. Просим прощения за доставленные неудобства.</Typography>
            </Paper>
          
            </Grid>        
          </Grid>

  <Grid container style={{ padding: '10px 10px' }} justify='center' spacing={1}>
    <Grid item xs={10} sm={12} md={12} lg={7} xl={6}>
    {props.ticketList.map((item,index) => {
                    const {dateOfIssue, ticketId, ticketNumber, daysInUse, interestRate, interestsToThisDay, loan, stateReadable, ticketName, thisRowIsSelected} = item;
                   
                    
                        return (
                            
                            <TicketItem
                             ticketName={ticketName}
                             key={ticketId}
                             ticketId={ticketId}
                             ticketNumber={ticketNumber}
                             daysInUse={daysInUse}
                             interestsToThisDay={interestsToThisDay}
                             loan={loan}
                             dateOfIssue={moment(dateOfIssue).format("DD.MM.YYYY HH:mm")}
                             stateReadable={stateReadable}
                             interestRate={interestRate}
                             handleChange={handleChange}
                             handleClickOnLink={handleClickOnLink}
                             thisRowIsSelected={thisRowIsSelected}
                            />
                    )
                    })
                }
          </Grid>
  </Grid>        

  {/* <Grid container style={{ padding: '3px 3px' }} justify='center' spacing={1}>
    <Grid item xs={6}>

{totalInterests > 0 ? (<Grid container spacing={5} >
    <Grid item xs={10} style={{ marginTop: '5px', textAlign: "right"}}><span style={{fontSize: '1.4em',fontWeight: 650,}}><WithNumberFormat value={totalInterests} digits={2} />&nbsp;₽</span></Grid>
    <Grid item xs={2}><Button variant='contained' style={{ backgroundColor: '#1ABC9C', color: 'white' }} >Оплатить</Button></Grid>
    </Grid>) : null}


    </Grid>
    </Grid> */}

{totalInterests > 0 ? (
<AppBar position="fixed" color="secondary" className={classes.appBar}>
        <Toolbar>
        <div className={classes.root} />
        <span style={{fontSize: '1.4em',fontWeight: 650, minWidth: '130px'}}><WithNumberFormat value={totalInterests} digits={2} />&nbsp;₽</span>
        <Button edge="end" variant='contained' disabled="true" style={{ backgroundColor: '#1ABC9C', color: 'white', minWidth: '80px' }} >Оплатить</Button>
        </Toolbar>
      </AppBar>
) : null}







          </React.Fragment>
      )
  }

  const mapStateToProps = (state) => {
    return {
      objectId: state.auth.userId,
      customerId: state.ticket.customerId,
      ticketList: state.ticket.ticketList,
      ticketListMemorized: state.ticket.ticketListMemorized
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
        clearCustomerTicketSubscription: (objectId) => dispatch(actions.clearCustomerTicketSubscription(objectId)),
        getTicketList: (customerId) => dispatch(actions.getTicketList(customerId)),
        memorizeTicketList: (ticketList) => dispatch(actions.memorizeTicketList(ticketList)),
        clearSingleTicket: () => dispatch(actions.clearSingleTicket()),
        

    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(ListForCustomerConfirmed));

  