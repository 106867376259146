import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import IconButton from "@material-ui/core/IconButton";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import * as actions from "../../../store/actions";
import CancelSvg from "../../UI/Icons/CancelSvg";
import { ButtonBase } from "@material-ui/core";
import Resizer from "react-image-file-resizer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    display: "qone",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 750,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: 224,
    maxHeight: 160,
  },
  imgPreview: {
    //margin: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    //display: "block",
    maxWidth: 180,
    maxHeight: 128,
  },
  cancelButton: {
    marginBottom: theme.spacing(5),
  },
  actionsContainer: {
    marginBottom: theme.spacing(4),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Приложить фото с паспортом",
    "Приложить фото главного разворота паспорта",
    "Приложить фото страницы с регистрацией",
    "Отправить фотографии",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <React.Fragment>
          <Paper style={{ margin: "auto" }}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file">
              <Button variant="raised" component="span">
                {" "}
                Upload{" "}
              </Button>
            </label>
          </Paper>
        </React.Fragment>
      );
    case 1:
      return "Тут будет возможность загрузить фото главного разворота паспорта";
    case 2:
      return `Тут будет загрузка страницы с регистрацией`;
    case 3:
      return "Всё загрузили? Отправляем фотографии товарищу майору";
    default:
      return "Unknown step";
  }
}

const getSrc = (step) => {
  let toReturn = "";
  switch (step) {
    case 0:
      toReturn = "IMG_2165.PNG";
      break;
    case 1:
      toReturn = "IMG_2166.JPG";
      break;
    case 2:
      toReturn = "IMG_2167.JPG";
      break;
  }

  return toReturn;
};

const getDisclaimer = (step) => {
  switch (step) {
    case 0:
      return (
        <ul>
          <li>Разверните паспорт на страницах с основной информацией.</li>
          <li>
            Держите развернутый паспорт рядом с собой на уровне лица или чуть
            ниже.
          </li>
          <li>Проследите, чтобы пальцы не перекрывали данные паспорта.</li>
          <li>
            Не нужно делать фотографию в полный рост – достаточно изображения по
            грудь или по пояс.
          </li>
          <li>
            Все паспортные данные должны быть видны и легко читаться с
            фотографии.
          </li>
          <li>Фотография должны быть четкой и не смазанной.</li>
          <li>
            Если на паспорте вы сфотографированы в очках, наденьте очки и для
            этой фотографии.
          </li>
        </ul>
      );
    case 1:
      return (
        <ul>
          <li>Откройте паспорт на страницах с основной информацией.</li>
          <li>Убедитесь, что разворот паспорта занимает большую часть кадра</li>
          <li>Проследите, чтобы посторонние объекты не попадали в кадр.</li>
          <li>
            Все паспортные данные должны быть видны и легко читаться с
            фотографии.
          </li>
        </ul>
      );
    case 2:
      return (
        <ul>
          <li>Откройте паспорт на страницах с информацией о регистрации.</li>
          <li>Убедитесь, что разворот паспорта занимает большую часть кадра</li>
          <li>Проследите, чтобы посторонние объекты не попадали в кадр.</li>
          <li>
            Все паспортные данные должны быть видны и легко читаться с
            фотографии.
          </li>
        </ul>
      );
  }
};

const Verification = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [step1File, setStep1File] = React.useState(null);
  const [step1Preview, setStep1Preview] = React.useState(null);
  const [step2File, setStep2File] = React.useState(null);
  const [step2Preview, setStep2Preview] = React.useState(null);
  const [step3File, setStep3File] = React.useState(null);
  const [step3Preview, setStep3Preview] = React.useState(null);
  const steps = getSteps();


  useEffect(() => {if(props.userSuccessfullyVerified) {setDialogOpen(true); }}, [props.userSuccessfullyVerified]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClickDialogOpen = () => { setDialogOpen(true); };
  const handleDialogClose = () => { setDialogOpen(false);props.history.push('/'); };

  const handleFile = (event) => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    //console.log('L 193. file=',file);
    reader.onloadend = () => {

      // // // // // var b64 = reader.result.replace(/^data:.+;base64,/, '');
         
      // // // // //      if (activeStep === 0) {
      // // // // //       setStep1File(b64);
      // // // // //       setStep1Preview(reader.result);
      // // // // //     }
      // // // // //     if (activeStep === 1) {
      // // // // //       setStep2File(b64);
      // // // // //       setStep2Preview(reader.result);
      // // // // //     }
      // // // // //     if (activeStep === 2) {
      // // // // //       setStep3File(b64);
      // // // // //       setStep3Preview(reader.result);
      // // // // //     }

      try {
        Resizer.imageFileResizer(
          file,
          1024,
          768,
          "JPEG",
          90,
          0,
          (uri) => {
            //console.log('L205. file=',file);
            var b64 = uri.replace(/^data:.+;base64,/, '');
            //console.log('L233 uri=',b64);
           // this.setState({ newImage: uri });
           if (activeStep === 0) {
            setStep1File(b64);
            //console.log("step1file=", uri);
            setStep1Preview(reader.result);
            
          }
          if (activeStep === 1) {
            setStep2File(b64);
            setStep2Preview(reader.result);
          }
          if (activeStep === 2) {
            setStep3File(b64);
            setStep3Preview(reader.result);
          }

          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log('тут ошибка!',err);
      }


      
    };
    reader.readAsDataURL(file);
    //console.log('L255 File=',file);
  };

  const getPreview = (step) => {
    switch (step) {
      case 0:
        return step1Preview;
      case 1:
        return step2Preview;
      case 2:
        return step3Preview;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep == 3) {
      props.saveUserVerificationInfo(step1File,step2File,step3File,props.userId,props.userLogin,props.userFirstName,props.userLastName,props.userPhone);
      //setDialogOpen(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCancelClick = () => {
    switch (activeStep) {
      case 0: {
        setStep1File(null);
        setStep1Preview(null);
        break;
      }
      case 1: {
        setStep2File(null);
        setStep2Preview(null);
        break;
      }
      case 2: {
        setStep3File(null);
        setStep3Preview(null);
        break;
      }
    }
  };

  const getCancelButton = (step) => {
    if (
      (step === 0 && step1Preview) ||
      (step === 1 && step2Preview) ||
      (step === 2 && step3Preview)
    )
      return (
        <Tooltip title="Отменить загрузку файла">
          <ButtonBase>
            {" "}
            <CancelSvg
              height="1.2em"
              width="1.2em"
              className={classes.cancelButton}
              onClick={handleCancelClick}
            />{" "}
          </ButtonBase>
        </Tooltip>
      );
    else return null;
  };

  return (
    <React.Fragment>

<Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Мы получили Ваши фотографии"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Ваши фотографии успешно загружены. В ближайшее время наши сотрудники выполнят верификацию и Вы получите доступ к закрытым разделам нашего сайта.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
    
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Paper
                className={classes.paper}
                elevation={activeStep !== 3 ? 5 : 0}
                style={{ marginBottom: "5px" }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <img
                      style={{
                        visibility: activeStep !== 3 ? "visible" : "hidden",
                      }}
                      className={classes.img}
                      alt="фотография паспорта"
                      src={getSrc(index)}
                    />
                  </Grid>

                  <Grid item xs={12} sm container spacing={1}>
                    <Grid item xs container direction="column" spacing={1}>
                      <Grid item xs>
                        <Typography gutterBottom component={'span'} variant="body2">
                          {getDisclaimer(index)}
                        </Typography>
                      </Grid>

                      <Grid item xs>
                      <form name="form1" method="post" encType="multipart/form-data" action="api/upload">
                        <Tooltip title="Загрузить изображение">
                          <input
                            accept="image/*"
                            className={classes.input}
                            style={{
                              visibility:
                                activeStep !== 3 ? "visible" : "hidden",
                            }}
                            id="raised-button-file"
                            type="file"
                            onChange={handleFile}
                          />
                        </Tooltip>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item>
                      <img
                        src={getPreview(index)}
                        className={classes.imgPreview}
                      />
                      {getCancelButton(index)}
                    </Grid>

                    <Grid item>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Пред.шаг
                          </Button>
                          <Button
                            variant="contained"
                            disabled={
                              activeStep === 3 &&
                              (step1Preview == null ||
                                step2Preview == null ||
                                step3Preview == null)
                            }
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? "Отправить все фотографии"
                              : "След.шаг"}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userFirstName: state.auth.userFirstName,
    userLastName: state.auth.userLastName,
    userLogin: state.auth.userLogin,
    userSuccessfullyVerified: state.profile.userSuccessfullyVerified,
    userPhone: state.auth.userPhone
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserVerificationInfo: (img1,img2,img3,customerId,customerLogin, customerFirstname, customerLastname, userPhone) => dispatch(actions.saveUserVerificationInfo(img1,img2,img3,customerId,customerLogin, customerFirstname,customerLastname, userPhone)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Verification));
